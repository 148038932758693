import React, { useState, useEffect } from "react";
import {
  Icon,
  Input,
  Image,
  Button,
  ImageGroup,
  Select,
  TextArea,
  Radio,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "../../constants";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { updateProduct, getStoreMenus } from "../../controllers";

const ProductUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { product, shop } = location.state;

  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [storeMenus, setStoreMenus] = useState([]);

  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price_initial);
  const [desc, setDesc] = useState(product.details?.description);
  const [preparingTime, setPreparingTime] = useState(product.preparing_time);
  const [menus, setMenus] = useState(product.category._id);
  const [isPublish, setIspublish] = useState(product.isPublished);
  const [isActive, setIsActive] = useState(product.isActive);
  const [images, setImages] = useState(product.images);

  useEffect(() => {
    getStoreMenus(product.shop)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setStoreMenus(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log({ error });
      });
  }, []);

  const styles = {
    input: {
      marginBottom: 5,
    },
  };

  const listMenus = () => {
    let list = [];
    storeMenus?.forEach((menu, i) =>
      list.push({ key: i, value: menu._id, text: menu.name })
    );
    return list;
  };

  const getMenuName = (one) => {
    let names = "";
    storeMenus.forEach((menu) => {
      if (menu._id === one) {
        names = menu.name;
      }
    });

    return names;
  };

  const goToUpdate = async () => {
    try {
      if (!name) {
        return setErr("Enter title please");
      }
      if (!price) {
        return setErr("Price can not be blank");
      }

      setIsLoading(true);
      const body = {
        category: menus ? menus : product.category._id,
        details: { description: desc ? desc : product.details.description },
        images: images ? images : product.images,
        isActive: isActive,
        isPublished: isPublish,
        name: name ? name : product.name,
        preparing_time: preparingTime
          ? Number(preparingTime)
          : product.preparing_time,
        price_initial: price ? Number(price) : product.price_initial,
      };
      console.log({ body, product });
      const respo = await updateProduct(product._id, body);
      setIsLoading(false);
      console.log({ respo });
      if (respo.status === 202) {
        return navigate("/store-details", { state: { shop } });
      }
    } catch (error) {
      setErr("Error occured, Please try again");
      setIsLoading(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <SideNav num={4} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div>
            <Segment>
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>

              <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
            </Segment>
          </div>
        ) : (
          <div style={{ marginLeft: 50, marginRight: 50, marginBottom: 50 }}>
            <h2 style={{ marginTop: 50 }}>Product Details</h2>

            <div>
              <Input
                value={name}
                fluid
                placeholder="Title"
                style={styles.input}
                onChange={(e) => {
                  setErr(null);
                  setName(e.target.value);
                }}
              />
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                  padding: "3%",
                  marginBottom: 5,
                  borderRadius: 5,
                  border: "2px dashed #dddddd",
                }}
              >
                <ImageGroup size="small">
                  {images.map((image) => (
                    <Image src={image} key={image} />
                  ))}
                </ImageGroup>

                {/* <Button
                  //onClick={() => setOpen1(true)}
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                  style={{ backgroundColor: colors.secondary }}
                >
                  <Icon name="plus" /> Change Photos
                </Button> */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Input
                  label="TZS"
                  style={styles.input}
                  placeholder="Price"
                  value={price}
                  widith={8}
                  onChange={(e) => {
                    setErr(null);
                    setPrice(e.target.value);
                  }}
                />
                <Input
                  label={{ basic: true, content: "mins" }}
                  labelPosition="right"
                  style={styles.input}
                  placeholder="Preparing Time"
                  value={preparingTime}
                  onChange={(e) => {
                    setErr(null);
                    setPreparingTime(e.target.value);
                  }}
                />
              </div>
              <TextArea
                placeholder="Description"
                value={desc}
                fluid
                style={{
                  minHeight: 100,
                  minWidth: "100%",
                  marginBottom: 5,
                  border: "1px solid #dddddd",
                  borderRadius: 5,
                  padding: 10,
                }}
                onChange={(e) => {
                  setErr(null);
                  setDesc(e.target.value);
                }}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Select
                  placeholder={getMenuName(menus)}
                  style={styles.input}
                  onChange={(b, e) => {
                    setErr(null);
                    setMenus(e.value);
                  }}
                  options={listMenus()}
                />
                <Radio
                  label={isPublish ? "Published" : "Not Published"}
                  checked={isPublish}
                  toggle
                  style={{ marginTop: 10 }}
                  onChange={(d, e) => {
                    setIspublish(e.checked);
                  }}
                />

                <Radio
                  label={isActive ? "Active" : "Not Active"}
                  checked={isActive}
                  toggle
                  style={{ marginTop: 10 }}
                  onChange={(d, e) => setIsActive(e.checked)}
                />
              </div>
            </div>
            {err ? (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  marginBottom: 50,
                  marginTop: 50,
                }}
              >
                {err}
              </p>
            ) : null}
            <Button
              onClick={goToUpdate}
              icon
              labelPosition="left"
              primary
              fluid
              size="medium"
              style={{
                backgroundColor: colors.primary,
                marginTop: 50,
                marginBottom: 30,
              }}
            >
              <Icon name="exchange" /> Update Product
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductUpdate;
