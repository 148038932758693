import React from "react";

export const MyCard = ({ rangi, title, center, down }) => (
  <div style={{ ...styles.card, backgroundColor: rangi, cursor: "pointer" }}>
    <p style={styles.heading}>{title}</p>
    <h1 style={styles.num}>{center}</h1>
    <div style={styles.percent}>{down}</div>
  </div>
);

const styles = {
  card: {
    height: 150,
    width: 260,
    borderRadius: 18,
    padding: 20,
    margin: 5,
  },
  heading: {
    color: "white",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 5,
  },
  num: { margin: 0, color: "white", fontSize: 30 },
  percent: {
    backgroundColor: "white",
    padding: 3,
    width: 50,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: 10,
    fontWeight: 500,
    fontSize: 12,
    opacity: 0.7,
  },
};
