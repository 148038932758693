import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  List,
  Image,
  Table,
  Icon,
  Label,
  Menu,
  Modal,
  Header,
} from "semantic-ui-react";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { useLocation } from "react-router-dom";
import { colors, rightStyles } from "../../constants";
import {
  getDropperOrders,
  acceptDropper,
  updateDropper,
  getDropperVehicles,
  addVehicleToDropper,
} from "../../controllers";
import { useNavigate } from "react-router-dom";
import "../../constants/style.css";

const DropperDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dropper } = location.state;

  const [orders, setOrders] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [page, setpage] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [ver, setVer] = useState("pending");
  const [upDating, setupDating] = useState(false);
  const [isVehicleAdding, setVehicleAdding] = useState(false);

  const [vName, setVname] = useState(null);
  const [vPlateNo, setVplateNo] = useState(null);
  const [vPlatFinal, setVPlatfinal] = useState(null);
  const [vKind, setVkind] = useState(null);
  const [vExpires, setVexpires] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    getDropperVehicles(dropper._id)
      .then((res) => {
        setVehicles(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
    getDropperOrders(10, page, dropper._id)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [page, refresh]);

  const heading = [
    "sn",
    "order no.",
    "status",
    "delivery",
    "tip",
    "total earn",
    "created at",
    "action",
  ];

  const headingVehicles = [
    "sn",
    "name",
    "plate no",
    "kind",
    "status",
    "expires at",
    "card",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  const verifyStore = async () => {
    try {
      setupDating(true);
      setOpen(false);
      if (ver) {
        await acceptDropper(dropper._id);
        setupDating(false);
      } else {
        await updateDropper({ is_enabled: false }, dropper._id);
        setupDating(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const addVehicle = async () => {
    try {
      if (!vExpires || !vKind || !vName || !vPlatFinal || !vPlateNo) {
        return alert("Add all fields");
      }
      const body = {
        name: vName,
        registration_card_url: "",
        initials_plate_no: "T",
        plate_number: vPlateNo,
        finals_plate_no: vPlatFinal,
        kind: vKind,
        driver: dropper._id,
        is_active: true,
        expire_on: vExpires,
      };
      setVehicleAdding(true);
      const resp = await addVehicleToDropper(body);
      setVehicleAdding(false);
      setOpen1(false);
      if (resp.status !== 201) {
        return alert(String(resp.data.message));
      }
      setVPlatfinal(null);
      setVexpires(null);
      setVkind(null);
      setVplateNo(null);
      setVname(null);
      return setRefresh(true);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div>
      <SideNav num={5} />
      <div className="container">
        <HeaderComp />
        <div style={{ marginLeft: 50, marginRight: 50, marginBottom: 50 }}>
          <div style={{ display: "flex", marginTop: 10, marginBottom: 20 }}>
            <Image src={dropper?.profile_img} size="tiny" circular />
          </div>

          <List horizontal relaxed style={{ marginBottom: 30 }}>
            <List.Item>
              <List.Content>
                <List.Header>
                  <Icon name="user" style={{ opacity: 0.3 }} />
                  {dropper.first_name} {dropper.last_name}
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  <Icon name="calendar" style={{ opacity: 0.3 }} />
                  {new Date(dropper.createdAt).toDateString()}
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header
                  as="a"
                  onClick={() =>
                    dropper.license_url
                      ? window.open(dropper.license_url, "_blank").focus()
                      : console.log("no doc")
                  }
                >
                  {" "}
                  <Icon name="cloud" style={{ opacity: 0.3 }} />
                  Licence pic
                </List.Header>
              </List.Content>
            </List.Item>
          </List>

          <Form>
            <Form.Group>
              <Form.Input
                label="Phone number"
                value={dropper?.phone}
                readOnly
                width={4}
              />
              <Form.Input
                label="NIDA"
                value={dropper?.nida}
                readOnly
                width={6}
              />
              <Form.Input
                label="Email"
                value={dropper?.email}
                readOnly
                width={6}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Licence No"
                placeholder={dropper?.license_no}
                width={8}
              />
              <Form.Input
                label="City"
                value={dropper.registered_city}
                readOnly
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Verification"
                value={dropper?.is_enabled ? "True" : "False"}
                readOnly
                width={6}
                icon={
                  <Label
                    onClick={() => setOpen(true)}
                    as="a"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="chevron right" />
                  </Label>
                }
              />
              <Form.Input
                label="Online Status"
                value={dropper?.is_online ? "True" : "False"}
                readOnly
                width={4}
              />
              <Form.Input
                label="Delivery Status"
                value={dropper?.is_delivering ? "True" : "False"}
                readOnly
                width={6}
              />
            </Form.Group>
            <Button loading={upDating} content="Save Changes" secondary />
          </Form>

          <h3 style={{ marginTop: 50 }}>Registered Vehicle</h3>

          <Table celled>
            <Table.Header>
              <Table.Row>
                {headingVehicles.map((one) => (
                  <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {vehicles.map((v, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{v.name}</Table.Cell>
                  <Table.Cell>
                    {v.initials_plate_no} {v.plate_number} {v.finals_plate_no}
                  </Table.Cell>
                  <Table.Cell>{v.kind}</Table.Cell>
                  <Table.Cell>{v.is_active ? "Active" : "Inactive"}</Table.Cell>
                  <Table.Cell>{v.expire_on}</Table.Cell>
                  <Table.Cell>
                    {v.registration_card_url ? (
                      <Button
                        size="tiny"
                        primary
                        onClick={() =>
                          window.open(v.registration_card_url, "_blank").focus()
                        }
                      >
                        View
                      </Button>
                    ) : (
                      "Unavailable"
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan="7">
                  <Button
                    onClick={() => setOpen1(true)}
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                    style={{ backgroundColor: colors.secondary }}
                  >
                    <Icon name="plus" /> Add Vehicle
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <h3 style={{ marginTop: 50 }}>{dropper?.first_name}'s Orders</h3>

          <Table celled>
            <Table.Header>
              <Table.Row>
                {heading.map((one) => (
                  <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {orders.map((order, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{order.order_no}</Table.Cell>
                  <Table.Cell
                    style={{
                      fontWeight: "bold",
                      color:
                        order.status === "delivered" ||
                        order.status === "closed"
                          ? colors.green
                          : order.status === "ready"
                          ? colors.secondary
                          : order.status === "dispatched"
                          ? "#8D0588"
                          : "#0D4AC1",
                    }}
                  >
                    {order.status}
                  </Table.Cell>
                  <Table.Cell>
                    Tsh {formartCurrency(order.shipping_fee)}
                  </Table.Cell>
                  <Table.Cell>
                    Tsh {formartCurrency(order.courier_tip)}
                  </Table.Cell>
                  <Table.Cell>
                    Tsh{" "}
                    {formartCurrency(order.shipping_fee + order.courier_tip)}
                  </Table.Cell>
                  <Table.Cell>
                    {new Date(order.createdAt).toDateString()}
                  </Table.Cell>
                  <Table.Cell
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/order-details", {
                        state: { order },
                      })
                    }
                  >
                    <Icon name="chevron right" />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Menu floated="right" pagination>
                    <Menu.Item
                      as="a"
                      icon
                      onClick={() =>
                        page > 0 ? setpage(page - 1) : console.log("First page")
                      }
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(0)}
                      style={page === 0 ? active : null}
                    >
                      1
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(1)}
                      style={page === 1 ? active : null}
                    >
                      2
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(2)}
                      style={page === 2 ? active : null}
                    >
                      3
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(3)}
                      style={page === 3 ? active : null}
                    >
                      4
                    </Menu.Item>
                    <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
      <Modal size="mini" open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Change Verification Status</Modal.Header>
        <Modal.Content>
          <Form.Select
            placeholder={dropper?.status}
            options={[
              { key: "2", text: "Decline", value: false },
              { key: "1", text: "Accepted", value: true },
            ]}
            onChange={(e, i) => {
              setVer(i.value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button positive onClick={verifyStore}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setOpen1(false)}
        onOpen={() => setOpen1(true)}
        open={open1}
      >
        <Modal.Header>Add Vehicle</Modal.Header>

        <Form style={{ padding: 30 }}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              onChange={(e) => {
                setVname(e.target.value);
              }}
              id="form-subcomponent-shorthand-input-first-name"
              label="Name"
              placeholder="IST"
            />
            <Form.Select
              label="Kind"
              placeholder="Kind"
              options={[
                { key: "2", text: "Boda Boda", value: "boda" },
                { key: "1", text: "Car", value: "car" },
              ]}
              onChange={(e, i) => {
                setVkind(i.value);
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="1"
              label="Plate no"
              placeholder="312"
              onChange={(e) => {
                setVplateNo(e.target.value);
              }}
            />
            <Form.Input
              fluid
              id="2"
              label="Plate no final"
              placeholder="DKK"
              onChange={(e) => {
                setVPlatfinal(e.target.value);
              }}
            />
            <Form.Input
              fluid
              id="3"
              onChange={(e) => {
                setVexpires(e.target.value);
              }}
              label="Insurance expires on"
              placeholder="30-10-2024"
            />
          </Form.Group>
        </Form>

        <Modal.Actions>
          <Button color="black" onClick={() => setOpen1(false)}>
            Cancel
          </Button>
          <Button
            content="Add Now"
            labelPosition="right"
            icon="checkmark"
            onClick={addVehicle}
            positive
            loading={isVehicleAdding}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default DropperDetails;
