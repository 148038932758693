import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  List,
  Image,
  Table,
  Icon,
  Label,
  Menu,
  Modal,
  Header,
} from "semantic-ui-react";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { useLocation } from "react-router-dom";
import { colors, rightStyles } from "../../constants";
import { getBuyerOrders } from "../../controllers";
import { useNavigate } from "react-router-dom";
import "../../constants/style.css";

const BuyerDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { buyer } = location.state;

  const [orders, setOrders] = useState([]);
  const [page, setpage] = useState(0);
  const [open, setOpen] = useState(false);
  const [upDating, setupDating] = useState(false);

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    getBuyerOrders(10, page, buyer._id)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [page]);

  const heading = [
    "sn",
    "order no.",
    "status",
    "cost",
    "paid",
    "dropper",
    "created at",
    "action",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  return (
    <div>
      <SideNav num={3} />
      <div className="container">
        <HeaderComp />
        <div style={{ margin: 50 }}>
          <List horizontal relaxed style={{ marginBottom: 30 }}>
            <List.Item>
              <List.Content>
                <List.Header>
                  <Icon name="user" style={{ opacity: 0.3 }} />
                  {buyer.first_name} {buyer.last_name}
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  <Icon name="calendar" style={{ opacity: 0.3 }} />
                  {new Date(buyer.createdAt).toDateString()}
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  <Icon name="calendar" style={{ opacity: 0.3 }} />
                  {new Date(buyer.updatedAt).toDateString()}
                </List.Header>
              </List.Content>
            </List.Item>
          </List>

          <Form>
            <Form.Group>
              <Form.Input
                label="Phone number"
                value={buyer?.phone}
                readOnly
                width={4}
              />
              <Form.Input
                label="Username"
                value={buyer?.username}
                readOnly
                width={6}
              />
              <Form.Input
                label="Status"
                value={buyer?.status}
                readOnly
                width={6}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Device Token"
                value={buyer?.deviceToken}
                readOnly
                width={16}
              />
            </Form.Group>
          </Form>

          <h3 style={{ marginTop: 50 }}>{buyer?.first_name}'s Orders</h3>

          <Table celled>
            <Table.Header>
              <Table.Row>
                {heading.map((one) => (
                  <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {orders.map((order, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{order.order_no}</Table.Cell>
                  <Table.Cell
                    style={{
                      fontWeight: "bold",
                      color:
                        order.status === "delivered" ||
                        order.status === "closed"
                          ? colors.green
                          : order.status === "ready"
                          ? colors.secondary
                          : order.status === "dispatched"
                          ? "#8D0588"
                          : "#0D4AC1",
                    }}
                  >
                    {order.status}
                  </Table.Cell>
                  <Table.Cell>
                    Tsh {formartCurrency(order.total_cost)}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      fontWeight: "bold",
                      color: order.isPaid ? colors.green : "red",
                    }}
                  >
                    {order.isPaid ? "Paid" : "Unpaid"}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      style={{
                        fontWeight: "bold",
                        color: colors.white,
                        backgroundColor: order.courier ? colors.green : "red",
                      }}
                    >
                      {order.courier ? order.courier.first_name : "none"}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    {new Date(order.createdAt).toDateString()}
                  </Table.Cell>
                  <Table.Cell
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/order-details", {
                        state: { order },
                      })
                    }
                  >
                    <Icon name="chevron right" />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Menu floated="right" pagination>
                    <Menu.Item
                      as="a"
                      icon
                      onClick={() =>
                        page > 0 ? setpage(page - 1) : console.log("First page")
                      }
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(0)}
                      style={page === 0 ? active : null}
                    >
                      1
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(1)}
                      style={page === 1 ? active : null}
                    >
                      2
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(2)}
                      style={page === 2 ? active : null}
                    >
                      3
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(3)}
                      style={page === 3 ? active : null}
                    >
                      4
                    </Menu.Item>
                    <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default BuyerDetails;
