import React, { useState, useEffect } from "react";
import SideNav from "../../components/sideNav";
import {
  Icon,
  Menu,
  Table,
  Loader,
  Input,
  Label,
  Button,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { rightStyles } from "../../constants";
import HeaderComp from "../../components/header";
import {
  getAllStores,
  searchStoreByCity,
  searchStoreByPhone,
} from "../../controllers";
import { colors } from "../../constants";
import "../../constants/style.css";

function Orders() {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const [ogStores, setogStores] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [num, setNum] = useState(null);
  const [isSearch, setSearching] = useState(false);
  const [page, setpage] = useState(0);

  useEffect(() => {
    setLoading(true);
    getAllStores(50, page)
      .then((res) => {
        setLoading(false);

        setStores(res.data);
        setogStores(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [page]);

  const handleSearch = async () => {
    try {
      if (!num || num === "") {
        return setStores(ogStores);
      }
      setSearching(true);

      if (!isNaN(num)) {
        const response = await searchStoreByPhone(Number(num));
        setSearching(false);

        if (response.data && response.status === 200) {
          setStores(response.data);
        } else {
          setStores([]);
        }
      } else {
        const response = await searchStoreByCity(num);
        setSearching(false);

        if (response.data && response.status === 200) {
          setStores(response.data);
        } else {
          setStores([]);
        }
      }
    } catch (error) {
      setSearching(false);
      console.log({ error });
    }
  };

  const heading = [
    "sn",
    "name",
    "phone",
    "customer serv",
    "city",
    "status",
    "is online",
    "join at",
    "action",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  return (
    <div>
      <SideNav num={4} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                loading={isSearch}
                size="small"
                onChange={(e) => {
                  e.target.value ? setNum(e.target.value) : setStores(ogStores);
                }}
                icon={
                  <Icon
                    name="search"
                    inverted
                    circular
                    link
                    onClick={handleSearch}
                  />
                }
                placeholder="Search by phone or city "
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 36,
                    marginRight: 20,
                    marginBottom: 0,
                  }}
                >
                  Restaurants
                </p>
                <Button
                  icon
                  labelPosition="right"
                  color="red"
                  onClick={() => navigate("/add-store")}
                >
                  Add
                  <Icon name="plus" />
                </Button>
              </div>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  {heading.map((one) => (
                    <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {stores.map((shop, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{shop.name}</Table.Cell>
                    <Table.Cell>{shop.phone}</Table.Cell>
                    <Table.Cell>{shop.customerService}</Table.Cell>
                    <Table.Cell>{shop.address?.city}</Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor:
                          shop.status === "accepted"
                            ? colors.green
                            : shop.status === "rejected"
                            ? "red"
                            : colors.secondary,
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {shop.status}
                    </Table.Cell>
                    <Table.Cell>
                      <Label
                        style={{
                          backgroundColor: shop.is_online
                            ? colors.green
                            : "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {shop.is_online ? "online" : "offline"}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(shop.createdAt).toDateString()}
                    </Table.Cell>
                    <Table.Cell
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/store-details", {
                          state: { shop },
                        })
                      }
                    >
                      <Icon name="chevron right" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="9">
                    <Menu floated="right" pagination>
                      <Menu.Item
                        as="a"
                        icon
                        onClick={() =>
                          page > 0
                            ? setpage(page - 1)
                            : console.log("First page")
                        }
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(0)}
                        style={page === 0 ? active : null}
                      >
                        1
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(1)}
                        style={page === 1 ? active : null}
                      >
                        2
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(2)}
                        style={page === 2 ? active : null}
                      >
                        3
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(3)}
                        style={page === 3 ? active : null}
                      >
                        4
                      </Menu.Item>
                      <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default Orders;
