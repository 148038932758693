import React from "react";
import "./side.css";
import logo2 from "../../assets/imgs/logo2.png";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

function SideNav({ num }) {
  return (
    <div className="div">
      <Image src={logo2} size="small" />

      <label className="minMenu">Main Menu</label>
      <ul className="ul">
        <li
          className="li"
          style={{ backgroundColor: num === 1 ? "#8E171B" : null }}
        >
          <Link to="/">Dashboard</Link>
        </li>
        <li
          className="li"
          style={{ backgroundColor: num === 2 ? "#8E171B" : null }}
        >
          <Link to="/orders">Orders</Link>
        </li>
        <li
          className="li"
          style={{ backgroundColor: num === 3 ? "#8E171B" : null }}
        >
          <Link to="/customers">Customers</Link>
        </li>
        <li
          className="li"
          style={{ backgroundColor: num === 4 ? "#8E171B" : null }}
        >
          <Link to="/stores">Restaurants</Link>
        </li>
        <li
          className="li"
          style={{ backgroundColor: num === 5 ? "#8E171B" : null }}
        >
          <Link to="/droppers">Droppers</Link>
        </li>
        <li
          className="li"
          style={{ backgroundColor: num === 7 ? "#8E171B" : null }}
        >
          <Link to="/influencers">Influencers</Link>
        </li>
      </ul>
      <label className="minMenu">Finance Menu</label>
      <ul className="ul">
        <li
          className="li"
          style={{ backgroundColor: num === 8 ? "#8E171B" : null }}
        >
          <Link to="/revenues">Revenue</Link>
        </li>

        {/*<li
          className="li"
          style={{ backgroundColor: num === 9 ? "#8E171B" : null }}
        >
          <Link to="/transactions">Transactions</Link>
        </li>
         <li
          className="li"
          style={{ backgroundColor: num === 9 ? "#8E171B" : null }}
        >
          <Link to="/">Cashouts</Link>
        </li>
        <li
          className="li"
          style={{ backgroundColor: num === 10 ? "#8E171B" : null }}
        >
          <Link to="/">Cashins</Link>
        </li> */}
      </ul>
      <label className="minMenu">More Menu</label>
      <ul className="ul">
        <li
          className="li"
          style={{ backgroundColor: num === 6 ? "#8E171B" : null }}
        >
          <Link to="/admins">Admins</Link>
        </li>
      </ul>
    </div>
  );
}

export default SideNav;
