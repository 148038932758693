import React, { useState, useEffect } from "react";
import SideNav from "../../components/sideNav";
import { Icon, Menu, Table, Loader, Input } from "semantic-ui-react";
import { rightStyles } from "../../constants";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../../components/header";
import {
  getAllbuyers,
  searchBuyerByName,
  searchBuyerByPhone,
} from "../../controllers";
import { colors } from "../../constants";
import "../../constants/style.css";

function Orders() {
  const navigate = useNavigate();
  const [buyers, setBuyers] = useState([]);
  const [ogBuyers, setOgBuyers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [num, setNum] = useState(null);
  const [isSearch, setSearching] = useState(false);
  const [page, setpage] = useState(0);

  useEffect(() => {
    setLoading(true);
    getAllbuyers(50, page)
      .then((res) => {
        setLoading(false);
        setBuyers(res.data);
        setOgBuyers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [page]);

  const handleSearch = async () => {
    try {
      if (!num || num === "") {
        return setBuyers(ogBuyers);
      }
      setSearching(true);

      if (!isNaN(num)) {
        const response = await searchBuyerByPhone(Number(num));
        setSearching(false);

        if (response.data && response.status === 200) {
          setBuyers([response.data]);
        } else {
          setBuyers([]);
        }
      } else {
        const response = await searchBuyerByName(num);
        setSearching(false);

        if (response.data && response.status === 200) {
          setBuyers(response.data);
        } else {
          setBuyers([]);
        }
      }
    } catch (error) {
      setSearching(false);
      console.log({ error });
    }
  };

  const heading = [
    "sn",
    "first name",
    "last name",
    "phone",
    "username",
    "join at",
    "action",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  return (
    <div>
      <SideNav num={3} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                loading={isSearch}
                size="small"
                onChange={(e) => {
                  e.target.value ? setNum(e.target.value) : setBuyers(ogBuyers);
                }}
                icon={
                  <Icon
                    name="search"
                    inverted
                    circular
                    link
                    onClick={handleSearch}
                  />
                }
                placeholder="Search by phone or name "
              />
              <p style={{ fontWeight: "bold", fontSize: 36, marginRight: 20 }}>
                Customers
              </p>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  {heading.map((one) => (
                    <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {buyers.map((buyer, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{buyer.first_name}</Table.Cell>
                    <Table.Cell>{buyer.last_name}</Table.Cell>
                    <Table.Cell>{buyer.phone}</Table.Cell>
                    <Table.Cell>{buyer.username}</Table.Cell>
                    <Table.Cell>
                      {new Date(buyer.createdAt).toDateString()}
                    </Table.Cell>
                    <Table.Cell
                      onClick={() =>
                        navigate("/buyer-details", {
                          state: { buyer },
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="chevron right" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="7">
                    <Menu floated="right" pagination>
                      <Menu.Item
                        as="a"
                        icon
                        onClick={() =>
                          page > 0
                            ? setpage(page - 1)
                            : console.log("First page")
                        }
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(0)}
                        style={page === 0 ? active : null}
                      >
                        1
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(1)}
                        style={page === 1 ? active : null}
                      >
                        2
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(2)}
                        style={page === 2 ? active : null}
                      >
                        3
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(3)}
                        style={page === 3 ? active : null}
                      >
                        4
                      </Menu.Item>
                      <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default Orders;
