import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  List,
  Input,
  Table,
  Icon,
  Label,
  Loader,
  Modal,
  Header,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { useLocation } from "react-router-dom";
import { colors, rightStyles } from "../../constants";
import {
  asignSpecificDropperOrder,
  completeTheOrder,
  findAgainDropperOrder,
  getDropperByPhone,
  getOnlineDroppers,
  getOrderById,
  updateOrderStatus,
} from "../../controllers";
import "../../constants/style.css";

const OrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //const { order } = location.state;

  const [page, setpage] = useState(0);
  const [order, setOrder] = useState(location.state.order);
  const [online, setonline] = useState(0);
  const [open, setOpen] = useState(false);
  const [isSearch, setisSearch] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const [dropper, setDropper] = useState(null);
  const [err, setError] = useState(null);
  const [isFinding, setFinding] = useState(false);
  const [hasSent, setSent] = useState(false);
  const [openTrack, setOpenTrack] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    setSent(false);
    getOrderById(order._id)
      .then((e) => setOrder(e.data))
      .catch((err) => console.log({ err }));
    getOnlineDroppers()
      .then((num) => {
        if (num.status === 200) {
          setonline(num.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const updateTracking = async () => {
    try {
      if (selectedStatus) {
        await updateOrderStatus(order._id, selectedStatus);
      }
      return window.location.reload();
    } catch (error) {
      console.log({ error });
    }
  };

  const completeOrder = async () => {
    try {
      await completeTheOrder(order._id);

      return window.location.reload();
    } catch (error) {
      console.log({ error });
    }
  };

  const status = [
    { key: "0", text: "Created", value: "created" },
    { key: "1", text: "Confirmed", value: "confirmed" },
    { key: "2", text: "Assigned", value: "assigned" },
    { key: "3", text: "Packing", value: "packing" },
    { key: "4", text: "Ready", value: "ready" },
    { key: "5", text: "Received", value: "received" },
    { key: "6", text: "Dispatched", value: "dispatched" },
    { key: "7", text: "Returned", value: "returned" },
    { key: "8", text: "Rejected", value: "rejected" },
    { key: "9", text: "Refunded", value: "refunded" },
    { key: "10", text: "Canceled", value: "canceled" },
  ];

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const calculateIndividual = (one) => {
    let sum = one.product.price_initial;
    let optionsTotal = 0;
    one.options?.forEach((option) => {
      optionsTotal = optionsTotal + option.cost;
    });
    let total = one.total_counts * (sum + optionsTotal);
    return total;
  };

  const unitCost = (one) => {
    let sum = one.product.price_initial;
    let optionsTotal = 0;
    one.options?.forEach((option) => {
      optionsTotal = optionsTotal + option.cost;
    });
    let total = sum + optionsTotal;
    return total;
  };

  const handleSearch = async () => {
    try {
      if (!searchVal) {
        return;
      }
      setisSearch(true);
      const res = await getDropperByPhone(Number(searchVal));
      setisSearch(false);
      if (res.status !== 200) {
        setError(res.data.message);
      }
      return setDropper(res.data);
    } catch (error) {
      setisSearch(false);
      console.log({ error });
    }
  };

  const findOnline = async () => {
    try {
      const num = await getOnlineDroppers();

      if (num.status === 200) {
        setonline(num.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findDropper = async (n) => {
    try {
      setFinding(true);
      if (n === 1) {
        const resp = await findAgainDropperOrder(order._id);
        setFinding(false);
        if (resp.status === 201) {
          setSent(true);
        } else {
          setError(resp.data.message);
        }
      } else {
        const resp = await asignSpecificDropperOrder(
          { courier: dropper[0]._id },
          order._id
        );
        setFinding(false);
        if (resp.status === 202) {
          setSent(true);
        } else {
          setError(resp.data.message);
        }
      }
    } catch (error) {
      setFinding(false);
      console.log({ error });
    }
  };

  const headings = [
    "s/n",
    "count",
    "food",
    "options",
    "unit cost",
    "total cost",
  ];

  const headingTracking = ["s/n", "status", "Time"];

  return (
    <div>
      <SideNav num={2} />
      <div className="container">
        <HeaderComp />
        <div style={{ margin: 50 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: 18 }}>
              Order #{" "}
              <label
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/store-details", {
                    state: { shop: order.shop },
                  })
                }
              >
                {" "}
                {order.order_no} {order.shop.name}
              </label>
            </p>
            <p style={{ fontWeight: "bold", fontSize: 18, marginTop: -10 }}>
              Ordered by :
              <label
                style={{ cursor: "pointer" }}
                onClick={() =>
                  order.buyer
                    ? navigate("/buyer-details", {
                        state: { buyer: order.buyer },
                      })
                    : {}
                }
              >
                {order.isGuest
                  ? "Guest"
                  : `${order.buyer?.first_name} ${order.buyer?.last_name}`}
              </label>
            </p>
          </div>

          <List horizontal relaxed style={{ marginBottom: 30 }}>
            <List.Item>
              <List.Content>
                <List.Header>
                  Dropper : <Icon name="motorcycle" style={{ opacity: 0.3 }} />
                  <Label
                    color={order.courier ? "color" : "orange"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      order.courier
                        ? navigate("/dropper-details", {
                            state: { dropper: order.courier },
                          })
                        : null
                    }
                  >
                    {order.courier ? order.courier.first_name : "None"}
                  </Label>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  Created At :{" "}
                  <Icon name="calendar outline" style={{ opacity: 0.3 }} />
                  <label>
                    {new Date(order.createdAt).toDateString().toUpperCase()}{" "}
                    {new Date(order.createdAt)
                      .toLocaleTimeString()
                      .toUpperCase()}
                  </label>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  Paid status :{" "}
                  <Icon name="money outline" style={{ opacity: 0.3 }} />
                  <label style={{ color: order.isPaid ? colors.green : "red" }}>
                    {order.isPaid ? "PAID" : "UNPAID"}
                  </label>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  Payment Mode :{" "}
                  <Icon name="money outline" style={{ opacity: 0.3 }} />
                  <label style={{ color: colors.green }}>
                    {order.pay_method.toUpperCase()}
                  </label>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  Self Pick Up :{" "}
                  <label style={{ color: colors.primary }}>
                    {order.isSelfPickup ? "TRUE" : "FALSE"}
                  </label>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  Pick-up code :{" "}
                  <Icon name="motorcycle" style={{ opacity: 0.3 }} />
                  <label style={{ color: colors.primary }}>
                    {order.pickup_no.toUpperCase()}
                  </label>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>
                  Rattings : <Icon name="star" color="yellow" />
                  {order.user_review?.Overall ? (
                    <label style={{ color: colors.primary }}>
                      {order.user_review?.Overall}/5
                    </label>
                  ) : (
                    <label style={{ color: colors.primary }}>N/A</label>
                  )}
                </List.Header>
              </List.Content>
            </List.Item>
          </List>

          <Form>
            <Form.Group>
              <Form.Input
                label="Order Status"
                value={order.status}
                readOnly
                width={4}
              />
              <Form.Input
                label="Total Cost"
                value={`Tsh ${formartCurrency(order.total_cost)}`}
                readOnly
                width={4}
              />
              <Form.Input
                label="Store Income"
                value={`Tsh ${formartCurrency(order.store_income)}`}
                readOnly
                width={4}
              />
              <Form.Input
                label="Shipping Fee"
                value={`Tsh ${formartCurrency(order.shipping_fee)}`}
                readOnly
                width={4}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Charged"
                value={`${order.store_charges?.percentage} %`}
                readOnly
                width={2}
              />
              <Form.Input
                label="Service Fee"
                value={`Tsh ${formartCurrency(order.service_fee)}`}
                readOnly
                width={2}
              />
              <Form.Input
                label="Packaging Fee"
                value={
                  order.package_fee
                    ? `Tsh ${formartCurrency(order.package_fee)}`
                    : "0"
                }
                readOnly
                width={3}
              />
              <Form.Input
                label="Dropper Tip"
                value={
                  order.courier_tip
                    ? `Tsh ${formartCurrency(order.courier_tip)}`
                    : "0"
                }
                readOnly
                width={3}
              />
              <Form.Input
                label="Address"
                value={order.address.street}
                readOnly
                width={6}
              />
            </Form.Group>
          </Form>

          {order.user_review ? (
            <div
              style={{
                marginTop: 50,
                backgroundColor:
                  order.user_review.Overall > 2.4 ? "#4EF1D0" : "#FFEE59",
                padding: 8,
              }}
            >
              <p style={{ fontWeight: "bold", fontSize: 18, margin: 0 }}>
                Order Review
              </p>
              <div style={{ display: "flex" }}>
                <p style={{ fontWeight: 600, margin: 0, marginRight: 10 }}>
                  Food Quality: {order.user_review.food_quality}
                </p>
                <p style={{ fontWeight: 600, margin: 0, marginRight: 10 }}>
                  Food Packaging: {order.user_review.food_packaging}
                </p>
                <p style={{ fontWeight: 600, marginRight: 10 }}>
                  Delivery Time: {order.user_review.delivery_time}
                </p>
                <p style={{ fontWeight: 600, margin: 0, marginRight: 10 }}>
                  Overall: {order.user_review.Overall}
                </p>
              </div>
              <p style={{ fontWeight: "bold" }}>
                Comment: {order.user_review.comment}
              </p>
            </div>
          ) : null}

          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <div>
              <p style={{ fontWeight: "bold", fontSize: 18 }}>Order Tracking</p>

              {order.status !== "delivered" ? (
                <div>
                  <Button
                    onClick={() => setOpenTrack(true)}
                    content="change status"
                    color="green"
                  />

                  <Button
                    onClick={() => setOpenComplete(true)}
                    content="Complete the Order"
                    color="blue"
                  />
                </div>
              ) : null}
            </div>

            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  {headingTracking.map((head) => (
                    <Table.HeaderCell singleLine>{head}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {order.tracking?.map((item, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell singleLine>{item.status}</Table.Cell>
                    <Table.Cell>
                      {new Date(item.time).toLocaleString()}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: 18 }}>Items</p>
            {!order.courier && order.isPaid ? (
              <Button
                onClick={() => setOpen(true)}
                content="Find Dropper"
                color="orange"
              />
            ) : null}
          </div>

          <Table celled padded>
            <Table.Header>
              <Table.Row>
                {headings.map((head) => (
                  <Table.HeaderCell singleLine>{head}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {order.items?.map((item, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell singleLine>{item.total_counts}x</Table.Cell>
                  <Table.Cell>{item.product.name}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {item.options?.map((one, j) => (
                      <p key={j}>
                        {one.name} = Tsh {formartCurrency(one.cost)}
                      </p>
                    ))}
                  </Table.Cell>
                  <Table.Cell>Tsh {formartCurrency(unitCost(item))}</Table.Cell>
                  <Table.Cell>
                    Tsh {formartCurrency(calculateIndividual(item))}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Find Dropper</Modal.Header>
        <Modal.Content image>
          <Button.Group vertical labeled icon>
            <Button
              icon="shuffle"
              content="Find automatically"
              color="orange"
              onClick={() => {
                setpage(0);
                findOnline();
                setSent(false);
              }}
            />
            <Button
              icon="play"
              content="Asign specific dropper"
              color="blue"
              onClick={() => {
                setSent(false);
                setpage(1);
              }}
            />
          </Button.Group>

          <Modal.Description>
            <Header>
              {page === 0
                ? "Find Dropper Automatically"
                : "Asign Specific Dropper"}
            </Header>
            {err ? <p style={{ color: "red" }}>{err}</p> : null}

            {page === 0 ? (
              <div>
                <Button
                  color="red"
                  content="Droppers online"
                  icon="recycle"
                  label={{
                    basic: true,
                    color: "red",
                    pointing: "left",
                    content: `${online}`,
                  }}
                />
                {hasSent ? (
                  <Button
                    active={false}
                    color="green"
                    content="Request sent"
                    icon="checkmark"
                  />
                ) : (
                  <Button
                    loading={isFinding}
                    color="orange"
                    content="Send request"
                    icon="search"
                    onClick={() => findDropper(1)}
                  />
                )}
              </div>
            ) : (
              <div>
                <Input
                  loading={isSearch}
                  size="small"
                  onChange={(e) => {
                    setError(false);
                    setSearchVal(e.target.value);
                  }}
                  icon={
                    <Icon
                      name="search"
                      inverted
                      circular
                      link
                      onClick={handleSearch}
                    />
                  }
                  placeholder="Search by phone "
                />
                {dropper && dropper.length > 0 ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Phone</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Is delivering</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {dropper.map((one, i) => (
                        <Table.Row
                          key={i}
                          negative={one.is_delivering || !one.is_enabled}
                          positive={!one.is_delivering || one.is_enabled}
                        >
                          <Table.Cell>
                            {one.first_name} {one.last_name}
                          </Table.Cell>
                          <Table.Cell>{one.phone}</Table.Cell>
                          <Table.Cell>
                            <Icon
                              name={one.is_enabled ? "checkmark" : "close"}
                            />
                            {one.is_enabled ? "Approved" : "Not Approved"}
                          </Table.Cell>
                          <Table.Cell>
                            {one.is_delivering ? "True" : "false"}
                          </Table.Cell>
                          <Table.Cell>
                            {one.is_delivering || !one.is_enabled ? (
                              "Can't assign"
                            ) : (
                              <div>
                                {isFinding ? (
                                  <Button loading color="blue" />
                                ) : (
                                  <div>
                                    {hasSent ? (
                                      <Button
                                        active={false}
                                        size="mini"
                                        icon="checkmark"
                                        color="green"
                                        content="Sent"
                                      />
                                    ) : (
                                      <Label
                                        as="a"
                                        color="blue"
                                        onClick={() => findDropper(2)}
                                      >
                                        Assign
                                      </Label>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : null}
                {dropper && dropper.length === 0 ? (
                  <p style={{ color: "orange", margin: 10 }}>
                    Dropper not found
                  </p>
                ) : null}
              </div>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>

      <Modal size="mini" open={openTrack} onClose={() => setOpenTrack(false)}>
        <Modal.Header>Change Order Status</Modal.Header>
        <Modal.Content>
          <Form.Select
            placeholder="Status"
            options={status}
            onChange={(e, i) => {
              setSelectedStatus(i.value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenTrack(false)}>
            Cancel
          </Button>
          <Button positive onClick={updateTracking}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="mini"
        open={openComplete}
        onClose={() => setOpenComplete(false)}
      >
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content>
          <p>
            The order will be marked as delivered. Confirm if the order has been
            delivered successfully otherwise cancel.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenComplete(false)}>
            Cancel
          </Button>
          <Button positive onClick={completeOrder}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default OrderDetails;
