import React, { useState, useEffect } from "react";
import SideNav from "../../components/sideNav";
import {
  Icon,
  Menu,
  Table,
  Loader,
  Input,
  Card,
  Dimmer,
  Dropdown,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../../components/header";
import { getAllOrdersByTime } from "../../controllers";
import { colors } from "../../constants";
import "../../constants/style.css";
import { MyCard } from "../../components/more";

function Revenues() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [orderValue, setOrderValue] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [muda, setMuda] = useState("today");

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const calculateTotals = (ords) => {
    let all = 0;
    let rev = 0;
    let loss = 0;

    ords.forEach((e) => {
      all = all + e.total_cost;
      if (e.isPaid || e.pay_method === "cash") {
        rev = rev + e.total_cost;
      } else {
        loss = loss + e.total_cost;
      }
    });

    setOrderValue(all);
    setTotalLoss(loss);
    setTotalRevenue(rev);

    return;
  };

  useEffect(() => {
    setLoading(true);
    getAllOrdersByTime(muda)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setOrders(res.data.orders);
          setOrderCount(res.data.count);
          calculateTotals(res.data.orders);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [muda]);

  const options = [
    { key: 1, text: "Today", value: "today" },
    { key: 2, text: "Weekly", value: "week" },
    { key: 3, text: "Monthly", value: "month" },
    { key: 4, text: "All Time", value: "all" },
  ];

  return (
    <div>
      <SideNav num={8} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 36,
                  marginRight: 20,
                  marginBottom: 0,
                }}
              >
                Revenues
              </p>
            </div>
            <div>
              <Dropdown
                options={options}
                selection
                value={muda}
                fluid
                placeholder="Select Time"
                onChange={(e, d) => setMuda(d.value)}
              />
              <Dimmer active={isLoading}>
                <Loader />
              </Dimmer>
            </div>
            <div style={{ paddingTop: 20 }}>
              <Card.Group centered>
                <MyCard
                  rangi={colors.secondary}
                  title="Total Orders"
                  center={`${orderCount}`}
                />
                <MyCard
                  rangi={colors.fourth}
                  title="Total Orders Value (TZS)"
                  center={`${formartCurrency(orderValue)}`}
                />
                <MyCard
                  rangi={colors.green}
                  title="Total Revenue (TZS)"
                  center={`${formartCurrency(totalRevenue)}`}
                  down={`+${((totalRevenue / orderValue) * 100).toFixed(0)}%`}
                />
                <MyCard
                  rangi={colors.primary}
                  title="Total Order Loss (TZS)"
                  center={`${formartCurrency(totalLoss)}`}
                  down={`-${((totalLoss / orderValue) * 100).toFixed(0)}%`}
                />
              </Card.Group>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Revenues;
