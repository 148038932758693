export const colors = {
  primary: "#8E171B",
  secondary: "#F9A01B",
  white: "#ffffff",
  dark: "#081A2B",
  third: "#FFD200",
  fourth: "#02386e",
  green: "#00CA2F",
  darkBackground: "#000000",
  lightBackground: "#eeeeee",
  primaryOpa: "#8E171B5F",
};

export const rightStyles = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 210,
  marginRight: 30,
  marginTop: 10,
};
