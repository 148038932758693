import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const MenuExampleSizeMini = () => {
  const navigate = useNavigate();

  const [admin, setAdmin] = useState(null);
  const [signout, setSignOut] = useState(false);

  useEffect(() => {
    const auth = Cookies.get("auth");

    if (!auth) {
      return navigate("/login");
    }
    const authObj = JSON.parse(auth);
    if (!authObj.is_active) {
      return Cookies.remove("auth");
    }
    setAdmin(authObj);
  }, [signout]);

  //  const handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  return (
    <Menu size="tiny">
      <Menu.Menu position="right">
        <Dropdown item text="More">
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate("/profile")}>
              Profile
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                Cookies.remove("auth");
                setSignOut(true);
              }}
            >
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item>
          <Button primary>{admin?.name}</Button>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default MenuExampleSizeMini;
