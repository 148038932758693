import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Table,
  Icon,
  Label,
  Menu,
  Modal,
  Progress,
  Dropdown,
} from "semantic-ui-react";
import { shop_api } from "../../controllers/apis";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { useNavigate, useLocation } from "react-router-dom";
import { colors, rightStyles } from "../../constants";
import {
  getStoreOrders,
  updateStore,
  acceptStore,
  getStoreProducts,
  getStore,
  updateStoreWorkingHrs,
} from "../../controllers";
import "../../components/mycss.css";
import "../../constants/style.css";

const StoreDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const store = location.state.shop;

  const [orders, setOrders] = useState([]);
  const [shop, setShop] = useState(store);
  const [products, setProducts] = useState([]);
  const [page, setpage] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [ver, setVer] = useState("pending");
  const [upDating, setupDating] = useState(false);
  const [err, setErr] = useState(null);
  const [progress, setProgress] = useState(0);
  const [pass, setPass] = useState(false);

  //working time
  const [editingDay, setEditingDay] = useState(null);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [fromHrs, setFromHrs] = useState("08");
  const [fromMins, setFromMins] = useState("00");
  const [toHrs, setToHrs] = useState("22");
  const [toMins, setToMins] = useState("00");

  //fields
  const [cphone, setCPhone] = useState(shop.customerService);
  const [email, setEmail] = useState(shop.email);
  const [zones, setZones] = useState([]);
  const [percent, setPercent] = useState(shop.percent_chargeable);
  const [packageFee, setPackagefee] = useState(
    shop.packaging_fee ? shop.packaging_fee : 0
  );
  const [takeAway, setTakeAway] = useState(shop.self_pickup);
  const [isActive, setIsActive] = useState(shop.is_active);
  const [isOnline, setIsOnline] = useState(shop.is_online);
  const [isDeviler, setIsDeviler] = useState(shop.self_delivery);
  const [zone, setZone] = useState(shop.zone?._id);
  const [hasSmart, setHasSmart] = useState(shop.has_smart_phone);
  const [payStructure, setPayStructure] = useState(shop.pay_structure);
  const [workingDays, setWorkingDays] = useState([]);

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    getStore(location.state.shop._id)
      .then((res) => {
        if (res.status === 200) {
          if (!res.data._id) {
            return;
          }
          setShop(res.data);
        }
      })
      .catch((err) => console.log({ err }));

    shop_api
      .get("/zone")
      .then((res) => {
        if (res.status === 200) {
          let z = [];
          res.data.forEach((e) => {
            z.push({ key: e._id, text: e.name, value: e._id });
          });
          setZones(z);
        }
      })
      .catch((err) => console.log({ err }));
  }, []);

  useEffect(() => {
    getStoreOrders(10, page, shop._id)
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
    getStoreProducts(20, 0, shop._id)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [page]);

  const productHeading = [
    "sn",
    "image",
    "title",
    "price",
    "publish status",
    "description",
    "created at",
    "action",
  ];

  const heading = [
    "sn",
    "order no.",
    "status",
    "Income",
    "paid",
    "dropper",
    "created at",
    "action",
  ];

  const head_hr = ["index", "day", "hours", "action"];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  const saveStore = async () => {
    try {
      setProgress(30);
      setPass(false);
      if (isNaN(cphone)) {
        return setErr("Phone number is invalid");
      }
      if (isNaN(percent) || Number(percent) > 100) {
        return setErr("Enter valid Percent");
      }
      setupDating(true);

      const body = {
        customerService: cphone,
        email: email,
        percent_chargeable: Number(percent),
        packaging_fee: Number(packageFee),
        self_pickup: takeAway,
        is_active: isActive,
        is_online: isOnline,
        self_delivery: isDeviler,
        pay_structure: payStructure,
        zone: zone,
        has_smart_phone: hasSmart,
      };

      const response = await updateStore(body, shop._id);

      if (response.status !== 202) {
        return setErr(response.data.message);
      }
      setProgress(100);
      setTimeout(() => {
        setPass(true);
        setupDating(false);
        return window.location.reload();
      }, 1000);
    } catch (error) {
      setupDating(false);
      console.log({ error });
    }
  };

  const verifyStore = async () => {
    try {
      setupDating(true);
      setOpen(false);
      if (ver === "accepted") {
        const response = await acceptStore(shop._id);
        console.log({ response });

        setupDating(false);
      } else {
        const response = await updateStore({ status: ver }, shop._id);
        console.log({ response });

        setupDating(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const addWorkingDays = async () => {
    try {
      setupDating(true);
      setOpen1(false);
      if (workingDays.length > 0) {
        const response = await updateStore(
          { working_hours: workingDays },
          shop._id
        );
        console.log({ data: response.data });
        setupDating(false);

        return window.location.reload();
      } else {
        setupDating(false);
      }
    } catch (error) {
      console.log({ error });
      setupDating(false);
    }
  };

  const updateHrs = async () => {
    try {
      const hrs = { from: `${fromHrs}:${fromMins}`, to: `${toHrs}:${toMins}` };
      console.log({ hrs, checkedCheckboxes });
      setupDating(true);

      checkedCheckboxes.forEach((check) => {
        updateStoreWorkingHrs({ day: check.day, hours: hrs }, shop._id)
          .then((res) => {
            if (res.status === 202) {
              setupDating(false);
              return window.location.reload();
            }
          })
          .catch((err) => {
            setupDating(false);

            console.log(err);
          });
      });
    } catch (error) {
      console.log({ error });
      setupDating(false);
    }
  };

  const daysOptions = [
    {
      key: 0,
      text: "Sunday",
      value: { day: 0, hours: { from: "08:00", to: "22:00" } },
    },
    {
      key: 1,
      text: "Monday",
      value: { day: 1, hours: { from: "08:00", to: "22:00" } },
    },
    {
      key: 2,
      text: "Tuesday",
      value: { day: 2, hours: { from: "08:00", to: "22:00" } },
    },
    {
      key: 3,
      text: "Wednesday",
      value: { day: 3, hours: { from: "08:00", to: "22:00" } },
    },
    {
      key: 4,
      text: "Thursday",
      value: { day: 4, hours: { from: "08:00", to: "22:00" } },
    },
    {
      key: 5,
      text: "Friday",
      value: { day: 5, hours: { from: "08:00", to: "22:00" } },
    },
    {
      key: 6,
      text: "Saturday",
      value: { day: 6, hours: { from: "08:00", to: "22:00" } },
    },
  ];

  const handleChange = (e, { value }) => {
    const keys = ["day"];
    let filtered = value.filter(
      (
        (s) => (o) =>
          ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
      )(new Set())
    );
    filtered = filtered.sort((a, b) => parseFloat(a.day) - parseFloat(b.day));
    setWorkingDays(filtered);
  };

  const handleCheckboxChange = (data) => {
    const isChecked = checkedCheckboxes.some(
      (checkedCheckbox) => checkedCheckbox.day === data.day
    );
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox.day !== data.day
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(data));
    }
  };

  const generateHrs = () => {
    let hrs1 = Array.from(Array(24), (x, i) =>
      ("0" + (23 - i)).slice(-2)
    ).reverse();
    let hrs = [];
    hrs1.forEach((hr) => hrs.push({ text: hr, value: hr }));
    return hrs;
  };

  const generateMin = () => {
    let min1 = Array.from(Array(60), (x, i) =>
      ("0" + (59 - i)).slice(-2)
    ).reverse();
    let mins = [];
    min1.forEach((hr) => mins.push({ text: hr, value: hr }));
    return mins;
  };

  return (
    <div>
      <SideNav num={4} />
      <div className="container">
        <HeaderComp />
        <div style={{ marginLeft: 50, marginRight: 50, marginBottom: 50 }}>
          <div style={{ marginBottom: 30 }}>
            <Image src={shop?.cover} size="medium" as="a" />
            <div style={{ display: "flex", marginTop: 10 }}>
              <Image src={shop?.logo} size="tiny" circular />
              <h2 style={{ marginLeft: 10 }}>{shop?.name}</h2>
            </div>
          </div>
          <Form>
            <Form.Group>
              <Form.Input
                label="Phone number"
                value={shop?.phone}
                readOnly
                width={4}
              />
              <Form.Input
                label="Customer Service"
                value={cphone}
                width={4}
                onChange={(e) => {
                  setErr(null);
                  setCPhone(e.target.value);
                }}
              />
              <Form.Input
                label="Email"
                value={email}
                width={6}
                onChange={(e) => {
                  setErr(null);
                  setEmail(e.target.value);
                }}
              />
              <Form.Input
                label="Package Fee (TZS)"
                value={packageFee}
                width={4}
                onChange={(e) => {
                  setErr(null);
                  setPackagefee(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Service Fee %"
                value={percent}
                width={2}
                onChange={(e) => {
                  setErr(null);
                  setPercent(e.target.value.slice(0, 3));
                }}
              />
              <Form.Input
                label="Address"
                value={`${shop.address?.street}, ${shop.address?.ward}, ${shop.address?.district}, ${shop.address?.city}`}
                width={5}
              />
              <Form.Select
                className="my-input"
                label="Payment Structure"
                options={[
                  { key: "1", text: "Instantly", value: "instantly" },
                  { key: "2", text: "Daily", value: "daily" },
                  { key: "3", text: "Weekly", value: "weekly" },
                ]}
                placeholder={payStructure}
                width={3}
                onChange={(e, i) => {
                  setErr(null);
                  setPayStructure(i.value);
                }}
              />
              <Form.Select
                label="Pick up status"
                options={[
                  { key: "1", text: "Take-away", value: true },
                  { key: "2", text: "Non take-away", value: false },
                ]}
                placeholder={takeAway ? "Take-away" : "Non take-away"}
                width={3}
                onChange={(e, i) => {
                  setErr(null);
                  setTakeAway(i.value);
                }}
              />
              <Form.Select
                label="Is Active"
                options={[
                  { key: "1", text: "True", value: true },
                  { key: "2", text: "False", value: false },
                ]}
                placeholder={isActive ? "True" : "False"}
                width={3}
                onChange={(e, i) => {
                  setErr(null);
                  setIsActive(i.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Verification"
                value={shop?.status}
                readOnly
                width={5}
                icon={
                  <Label
                    onClick={() => {
                      setErr(null);
                      setOpen(true);
                    }}
                    as="a"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon name="chevron right" />
                  </Label>
                }
              />

              <Form.Select
                label="Online Status"
                options={[
                  { key: "1", text: "Online", value: true },
                  { key: "2", text: "Offline", value: false },
                ]}
                placeholder={isOnline ? "Online" : "Offline"}
                width={3}
                onChange={(e, i) => {
                  setErr(null);
                  setIsOnline(i.value);
                }}
              />
              <Form.Select
                label="Has smart phone"
                options={[
                  { key: "1", text: "Yes", value: true },
                  { key: "2", text: "No", value: false },
                ]}
                placeholder={shop.has_smart_phone ? "Yes" : "No"}
                width={3}
                onChange={(e, i) => {
                  setErr(null);
                  setHasSmart(i.value);
                }}
              />
              <Form.Select
                label="Delivery Status"
                options={[
                  { key: "1", text: "Own Delivery", value: true },
                  { key: "2", text: "Msosidrop Droppers", value: false },
                ]}
                placeholder={isDeviler ? "Own Delivery" : "Msosidrop Droppers"}
                width={4}
                onChange={(e, i) => {
                  setErr(null);
                  setIsDeviler(i.value);
                }}
              />
              {zones.length > 0 ? (
                <Form.Select
                  label="Zone"
                  options={zones}
                  placeholder={shop.zone?.name}
                  width={3}
                  onChange={(e, i) => {
                    setErr(null);
                    setZone(i.value);
                  }}
                />
              ) : null}
            </Form.Group>
            {err ? <p style={{ color: "red" }}>{err}</p> : null}
            {upDating && !pass ? (
              <Progress percent={progress} autoSuccess />
            ) : null}
            <Button
              loading={upDating}
              onClick={saveStore}
              content="Save Restaurant"
              primary
            />
          </Form>

          <h2 style={{ marginTop: 50 }}>Working Hours</h2>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                {head_hr.map((head) => (
                  <Table.HeaderCell singleLine key={head}>
                    {head}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {shop.working_hours.map((one, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{one.day}</Table.Cell>
                  <Table.Cell singleLine>
                    {daysOptions[one.day].text}
                  </Table.Cell>
                  <Table.Cell>
                    {one.hours.from} - {one.hours.to}
                  </Table.Cell>

                  <Table.Cell style={{ cursor: "pointer" }}>
                    <Label
                      onClick={() => {
                        setEditingDay(one.day);
                        setOpen2(true);
                        setCheckedCheckboxes([one]);
                      }}
                      color="secondary"
                    >
                      Edit
                    </Label>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan="5">
                  <Button
                    onClick={() => setOpen1(true)}
                    floated="right"
                    icon
                    loading={upDating}
                    labelPosition="left"
                    primary
                    size="small"
                    style={{ backgroundColor: colors.primary }}
                  >
                    <Icon name="plus" /> Change Days
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <h2 style={{ marginTop: 50 }}>{shop?.name}'s Orders</h2>

          <Table celled>
            <Table.Header>
              <Table.Row>
                {heading.map((one) => (
                  <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {orders.map((order, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{order.order_no}</Table.Cell>
                  <Table.Cell
                    style={{
                      fontWeight: "bold",
                      color:
                        order.status === "delivered" ||
                        order.status === "closed"
                          ? colors.green
                          : order.status === "ready"
                          ? colors.secondary
                          : order.status === "dispatched"
                          ? "#8D0588"
                          : "#0D4AC1",
                    }}
                  >
                    {order.status}
                  </Table.Cell>
                  <Table.Cell>
                    Tsh {formartCurrency(order.store_income)}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      fontWeight: "bold",
                      color: order.isPaid ? colors.green : "red",
                    }}
                  >
                    {order.isPaid ? "Paid" : "Unpaid"}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      style={{
                        fontWeight: "bold",
                        color: colors.white,
                        backgroundColor: order.courier ? colors.green : "red",
                      }}
                    >
                      {order.courier ? order.courier.first_name : "none"}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    {new Date(order.createdAt).toDateString()}
                  </Table.Cell>
                  <Table.Cell
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/order-details", {
                        state: { order },
                      })
                    }
                  >
                    <Icon name="chevron right" />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Menu floated="right" pagination>
                    <Menu.Item
                      as="a"
                      icon
                      onClick={() =>
                        page > 0 ? setpage(page - 1) : console.log("First page")
                      }
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(0)}
                      style={page === 0 ? active : null}
                    >
                      1
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(1)}
                      style={page === 1 ? active : null}
                    >
                      2
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(2)}
                      style={page === 2 ? active : null}
                    >
                      3
                    </Menu.Item>
                    <Menu.Item
                      as="a"
                      onClick={() => setpage(3)}
                      style={page === 3 ? active : null}
                    >
                      4
                    </Menu.Item>
                    <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 50,
              justifyContent: "space-between",
            }}
          >
            <h2 style={{}}>Products</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon
                labelPosition="right"
                color="blue"
                // onClick={() => navigate("/add-product")}
              >
                Go to Menu
                <Icon name="chevron right" />
              </Button>
              <Button
                icon
                labelPosition="right"
                color="red"
                // onClick={() => navigate("/add-product")}
              >
                Add Product
                <Icon name="plus" />
              </Button>
            </div>
          </div>

          <Table celled padded>
            <Table.Header>
              <Table.Row>
                {productHeading.map((head) => (
                  <Table.HeaderCell singleLine key={head}>
                    {head}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {products.map((product, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell singleLine>
                    {" "}
                    <Image src={product?.images[0]} size="small" />
                  </Table.Cell>
                  <Table.Cell>{product.name}</Table.Cell>
                  <Table.Cell>
                    {formartCurrency(product.price_initial)}
                  </Table.Cell>
                  <Table.Cell>
                    <Label color={product.isPublished ? "blue" : "red"}>
                      {product.isPublished ? "Published" : "Unpublished"}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>{product.details.description}</Table.Cell>
                  <Table.Cell>
                    {new Date(product.createdAt).toLocaleDateString()}
                  </Table.Cell>
                  <Table.Cell
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/update-product", {
                        state: { product, shop },
                      })
                    }
                  >
                    <Icon name="chevron right" />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
      <Modal size="mini" open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Change Verification Status</Modal.Header>
        <Modal.Content>
          <Form.Select
            placeholder={shop?.status}
            options={[
              { key: "2", text: "Pending", value: "pending" },
              { key: "3", text: "Inreview", value: "inreview" },
              { key: "4", text: "Rejected", value: "rejected" },
              { key: "1", text: "Accepted", value: "accepted" },
            ]}
            onChange={(e, i) => {
              setVer(i.value);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button positive onClick={verifyStore}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={open1}
        onClose={() => {
          setOpen1(false);
        }}
      >
        <Modal.Header>Working Days</Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder="Days"
            fluid
            clearable
            multiple
            selection
            value={workingDays}
            options={daysOptions}
            onChange={handleChange}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              setOpen1(false);
            }}
          >
            Cancel
          </Button>
          <Button positive onClick={addWorkingDays}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>

      {editingDay === 0 || editingDay > 0 ? (
        <Modal
          open={open2}
          onClose={() => {
            setOpen2(false);
          }}
        >
          <Modal.Header>
            {daysOptions[editingDay].text} - Working Hours
          </Modal.Header>
          <Modal.Content>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h4>From</h4>
                <>
                  <Dropdown
                    placeholder="Hrs"
                    clearable
                    selection
                    options={generateHrs()}
                    onChange={(e, { value }) => setFromHrs(value)}
                  />
                  <Dropdown
                    placeholder="Mins"
                    clearable
                    selection
                    options={generateMin()}
                    onChange={(e, { value }) => setFromMins(value)}
                  />
                </>
              </div>
              <div>
                <h4>To</h4>
                <>
                  <Dropdown
                    placeholder="Hrs"
                    clearable
                    selection
                    options={generateHrs()}
                    onChange={(e, { value }) => setToHrs(value)}
                  />
                  <Dropdown
                    placeholder="Mins"
                    clearable
                    selection
                    options={generateMin()}
                    onChange={(e, { value }) => setToMins(value)}
                  />
                </>
              </div>
            </div>
            <div
              className="checkboxes"
              style={{ marginTop: 20, display: "flex" }}
            >
              {shop.working_hours?.map((data, index) => (
                <div style={{ marginRight: 10 }} key={`cb-${index}`}>
                  <input
                    value={data.day}
                    type="checkbox"
                    checked={checkedCheckboxes.some(
                      (checkedCheckbox) => checkedCheckbox.day === data.day
                    )}
                    onChange={() => handleCheckboxChange(data)}
                  />{" "}
                  {daysOptions[data.day].text}.
                </div>
              ))}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                setOpen2(false);
              }}
            >
              Cancel
            </Button>
            <Button positive loading={upDating} onClick={updateHrs}>
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
    </div>
  );
};

export default StoreDetails;
