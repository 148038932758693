import React, { useState, useEffect } from "react";
import SideNav from "../../components/sideNav";
import { useNavigate } from "react-router-dom";
import { Icon, Menu, Table, Loader, Input, Label } from "semantic-ui-react";
import { rightStyles } from "../../constants";
import HeaderComp from "../../components/header";
import { getAllOrders, getOrderByNo } from "../../controllers";
import { colors } from "../../constants";
import "../../constants/style.css";

function Orders() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [ogorders, setOgOrders] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [num, setNum] = useState(null);
  const [isSearch, setSearching] = useState(false);
  const [page, setpage] = useState(0);

  useEffect(() => {
    setLoading(true);
    getAllOrders(50, page)
      .then((res) => {
        setLoading(false);
        setOrders(res.data);
        setOgOrders(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [page]);

  const handleSearch = async () => {
    try {
      if (!num) {
        return setOrders(ogorders);
      }
      setSearching(true);
      const response = await getOrderByNo(num);
      setSearching(false);

      if (response.data && response.status === 200) {
        setOrders([response.data]);
      } else {
        setOrders([]);
      }
    } catch (error) {
      setSearching(false);
      console.log({ error });
    }
  };

  const formartCurrency = (amount) => {
    return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const heading = [
    "sn",
    "order no.",
    "status",
    "cost",
    "paid",
    "dropper",
    "created at",
    "action",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  return (
    <div>
      <SideNav num={2} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                loading={isSearch}
                size="small"
                onChange={(e) => {
                  e.target.value ? setNum(e.target.value) : setOrders(ogorders);
                }}
                icon={
                  <Icon
                    name="search"
                    inverted
                    circular
                    link
                    onClick={handleSearch}
                  />
                }
                placeholder="Search by order no... "
              />
              <p style={{ fontWeight: "bold", fontSize: 36, marginRight: 20 }}>
                Orders
              </p>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  {heading.map((one) => (
                    <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {orders.map((order, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{order.order_no}</Table.Cell>
                    <Table.Cell
                      style={{
                        fontWeight: "bold",
                        color:
                          order.status === "delivered" ||
                          order.status === "closed"
                            ? colors.green
                            : order.status === "ready"
                            ? colors.secondary
                            : order.status === "dispatched"
                            ? "#8D0588"
                            : "#0D4AC1",
                      }}
                    >
                      {order.status}
                    </Table.Cell>
                    <Table.Cell>
                      Tsh {formartCurrency(order.total_cost)}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        fontWeight: "bold",
                        color: order.isPaid ? colors.green : "red",
                      }}
                    >
                      {order.isPaid ? "Paid" : "Unpaid"}
                    </Table.Cell>
                    <Table.Cell>
                      <Label
                        style={{
                          fontWeight: "bold",
                          color: colors.white,
                          backgroundColor: order.courier ? colors.green : "red",
                        }}
                      >
                        {order.courier ? order.courier.first_name : "none"}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(order.createdAt).toDateString()}
                    </Table.Cell>
                    <Table.Cell
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/order-details", {
                          state: { order },
                        })
                      }
                    >
                      <Icon name="chevron right" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="8">
                    <Menu floated="right" pagination>
                      <Menu.Item
                        as="a"
                        icon
                        onClick={() =>
                          page > 0
                            ? setpage(page - 1)
                            : console.log("First page")
                        }
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(0)}
                        style={page === 0 ? active : null}
                      >
                        1
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(1)}
                        style={page === 1 ? active : null}
                      >
                        2
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(2)}
                        style={page === 2 ? active : null}
                      >
                        3
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(3)}
                        style={page === 3 ? active : null}
                      >
                        4
                      </Menu.Item>
                      <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default Orders;
