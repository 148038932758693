import {
  order_api,
  buyer_api,
  shop_api,
  dropper_api,
  admin_api,
  product_api,
} from "./apis";
const key = "6364bf4cbcf56432c1737a6m";

export const getAllOrders = async (limit, page) => {
  try {
    const response = await order_api.get(`/order?page=${page}&limit=${limit}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrderStatus = async (id, status) => {
  try {
    const response = await order_api.put(
      `/order/update/status/${id}`,
      { status },
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateProduct = async (id, body) => {
  try {
    const response = await product_api.put(`/product/update/${id}`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const completeTheOrder = async (id) => {
  try {
    const response = await order_api.put(
      `/order/update/complete/${id}`,
      {},
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllOrdersByTime = async (muda) => {
  try {
    const response = await order_api.get(`/order/byTime/${muda}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStore = async (id) => {
  try {
    const response = await shop_api.get(`/shop/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStoreOrders = async (limit, page, id) => {
  try {
    const response = await order_api.get(
      `/order/shop/${id}?page=${page}&limit=${limit}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getBuyerOrders = async (limit, page, id) => {
  try {
    const response = await order_api.get(
      `/order/mine/${id}?page=${page}&limit=${limit}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStoreProducts = async (limit, page, id) => {
  try {
    const response = await product_api.get(
      `/product/shop/all/${id}?page=${page}&limit=${limit}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllStoresByTime = async (muda) => {
  try {
    const response = await shop_api.get(`/shop/byTime/${muda}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getStoreMenus = async (storeId) => {
  try {
    const response = await product_api.get(`/category/shop/${storeId}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getDropperOrders = async (limit, page, id) => {
  try {
    const response = await order_api.get(
      `/order/courier/${id}?page=${page}&limit=${limit}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateStore = async (body, id) => {
  try {
    const response = await shop_api.put(`/shop/update/${id}`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateStoreWorkingHrs = async (body, id) => {
  try {
    const response = await shop_api.put(`/shop/update-hours/${id}`, body, {
      validateStatus: () => true,
    });
    console.log({ response });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const acceptStore = async (id) => {
  try {
    const response = await shop_api.put(`/shop/accept/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDroppersByTime = async (muda) => {
  try {
    const response = await dropper_api.get(
      `/courier/byTime/${muda}?api_key=${key}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getDropperByPhone = async (phone) => {
  try {
    const response = await dropper_api.get(
      `/courier/phone/${phone}?api_key=${key}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOnlineDroppers = async () => {
  try {
    const response = await dropper_api.get(
      `/courier/online/num?api_key=${key}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const acceptDropper = async (id) => {
  try {
    const response = await dropper_api.put(
      `/courier/verify/${id}?api_key=${key}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateDropper = async (body, id) => {
  try {
    const response = await dropper_api.put(
      `/courier/update/${id}?api_key=${key}`,
      body,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addVehicleToDropper = async (body) => {
  try {
    const response = await dropper_api.post(`/vehicle/add`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getDropperVehicles = async (driveId) => {
  try {
    const response = await dropper_api.get(`/vehicle/driver/${driveId}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllAdmins = async () => {
  try {
    const response = await admin_api.get(`/admin`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllbuyers = async (limit, page) => {
  try {
    const response = await buyer_api.get(`/buyer?page=${page}&limit=${limit}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDroppers = async (limit, page) => {
  try {
    const response = await dropper_api.get(
      `/courier?page=${page}&limit=${limit}&api_key=${key}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllStores = async (limit, page) => {
  try {
    const response = await shop_api.get(`/shop?page=${page}&limit=${limit}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderByNo = async (no) => {
  try {
    const response = await order_api.get(`/order/no/${no}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderById = async (id) => {
  try {
    const response = await order_api.get(`/order/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const asignSpecificDropperOrder = async (body, orderId) => {
  try {
    const response = await order_api.put(
      `/order/update/assign-driver/${orderId}`,
      body,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const findAgainDropperOrder = async (orderId) => {
  try {
    const response = await order_api.post(`/order/find/courier/${orderId}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const searchBuyerByPhone = async (phone) => {
  try {
    const response = await buyer_api.get(`/buyer/phone/${phone}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllBuyersByTime = async (muda) => {
  try {
    const response = await buyer_api.get(`/buyer/byTime/${muda}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const searchDropperByPhone = async (phone) => {
  try {
    const response = await dropper_api.get(
      `/courier/phone/${phone}?api_key=${key}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const searchStoreByPhone = async (phone) => {
  try {
    const response = await shop_api.get(`/shop/phone/${phone}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const searchStoreByCity = async (city) => {
  try {
    const response = await shop_api.get(`/shop/city/${city}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const searchBuyerByName = async (name) => {
  try {
    const response = await buyer_api.get(`/buyer/name/${name}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const loginAdmin = async (body) => {
  try {
    const response = await admin_api.post(`/admin/login/`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const signUpStore = async (body) => {
  try {
    const response = await shop_api.post(`/shop/add/`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const signUpDropper = async (body) => {
  try {
    const response = await dropper_api.post(
      `/courier/add?api_key=${key}`,
      body,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const signUpInfluencer = async (body) => {
  try {
    const response = await admin_api.post(`/influencer/add`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getInfluencers = async (limit, page) => {
  try {
    const response = await admin_api.get(
      `/influencer?page=${page}&limit=${limit}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
