import React, { useState, useEffect } from "react";
import SideNav from "../../components/sideNav";
import {
  Icon,
  Menu,
  Table,
  Loader,
  Input,
  Label,
  Button,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { rightStyles } from "../../constants";
import HeaderComp from "../../components/header";
import { getAllDroppers, searchDropperByPhone } from "../../controllers";
import { colors } from "../../constants";
import "../../constants/style.css";

function Orders() {
  const navigate = useNavigate();
  const [droppers, setDroppers] = useState([]);
  const [ogDroppers, setOgDroppers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [num, setNum] = useState(null);
  const [isSearch, setSearching] = useState(false);
  const [page, setpage] = useState(0);

  useEffect(() => {
    setLoading(true);
    getAllDroppers(50, page)
      .then((res) => {
        setLoading(false);

        setDroppers(res.data);
        setOgDroppers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [page]);

  const handleSearch = async () => {
    try {
      if (!num || num === "") {
        return setDroppers(ogDroppers);
      }
      setSearching(true);

      if (!isNaN(num)) {
        const response = await searchDropperByPhone(Number(num));
        setSearching(false);

        if (response.data && response.status === 200) {
          setDroppers(response.data);
        } else {
          setDroppers([]);
        }
      } else {
        setSearching(false);
      }
    } catch (error) {
      setSearching(false);
      console.log({ error });
    }
  };

  const heading = [
    "sn",
    "name",
    "phone",
    "status",
    "is online",
    "is delivering",
    "licence",
    "join at",
    "action",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  return (
    <div>
      <SideNav num={5} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                loading={isSearch}
                size="small"
                onChange={(e) => {
                  e.target.value
                    ? setNum(e.target.value)
                    : setDroppers(ogDroppers);
                }}
                icon={
                  <Icon
                    name="search"
                    inverted
                    circular
                    link
                    onClick={handleSearch}
                  />
                }
                placeholder="Search by phone "
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 36,
                    marginRight: 20,
                    marginBottom: 0,
                  }}
                >
                  Droppers
                </p>
                <Button
                  icon
                  labelPosition="right"
                  color="orange"
                  onClick={() => navigate("/add-dropper")}
                >
                  Add
                  <Icon name="plus" />
                </Button>
              </div>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  {heading.map((one) => (
                    <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {droppers.map((shop, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>
                      {shop.first_name} {shop.last_name}
                    </Table.Cell>
                    <Table.Cell>{shop.phone}</Table.Cell>

                    <Table.Cell>
                      <Label
                        style={{
                          backgroundColor: shop.is_enabled
                            ? colors.green
                            : "orange",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {shop.is_enabled ? "enabled" : "unenabled"}
                      </Label>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        backgroundColor: shop.is_online
                          ? colors.green
                          : colors.primaryOpa,
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {shop.is_online ? "online" : "offline"}
                    </Table.Cell>
                    <Table.Cell>
                      <Label
                        style={{
                          backgroundColor: shop.is_delivering
                            ? colors.green
                            : "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {shop.is_delivering ? "true" : "false"}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>{shop.license_no}</Table.Cell>
                    <Table.Cell>
                      {new Date(shop.createdAt).toDateString()}
                    </Table.Cell>
                    <Table.Cell
                      onClick={() =>
                        navigate("/dropper-details", {
                          state: { dropper: shop },
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="chevron right" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="9">
                    <Menu floated="right" pagination>
                      <Menu.Item
                        as="a"
                        icon
                        onClick={() =>
                          page > 0
                            ? setpage(page - 1)
                            : console.log("First page")
                        }
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(0)}
                        style={page === 0 ? active : null}
                      >
                        1
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(1)}
                        style={page === 1 ? active : null}
                      >
                        2
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(2)}
                        style={page === 2 ? active : null}
                      >
                        3
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(3)}
                        style={page === 3 ? active : null}
                      >
                        4
                      </Menu.Item>
                      <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default Orders;
