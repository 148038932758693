import React, { useEffect, useState } from "react";
import { Card, Icon, Image } from "semantic-ui-react";
import HeaderComp from "../../components/header";
import avatar from "../../assets/imgs/avatar.jpg";
import Cookies from "js-cookie";

const Profile = () => {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const auth = Cookies.get("auth");

    if (auth) {
      const authObj = JSON.parse(auth);
      setAdmin(authObj);
    }
  }, []);

  return (
    <div>
      <HeaderComp />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card>
          <Image src={avatar} wrapped ui={false} />
          <Card.Content>
            <Card.Header>{admin?.name}</Card.Header>
            <Card.Meta>
              Joined at {new Date(admin?.createdAt).toDateString()}
            </Card.Meta>
            <Card.Description>
              {admin?.email} | {admin?.access?.name}
            </Card.Description>
            <Card.Meta>
              {admin?.position} | level : {admin?.access.level}
            </Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Icon name="lock" />
              Change password
            </a>
          </Card.Content>
        </Card>
      </div>
    </div>
  );
};

export default Profile;
