import React, { useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import Cookies from "js-cookie";
import logo from "../../assets/imgs/logo.png";
import { loginAdmin } from "../../controllers";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const navigate = useNavigate();
  const [isSubmit, setSubmit] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setpassword] = useState(null);
  const [err, setErr] = useState(null);

  const handleSubmit = async () => {
    try {
      if (!password || !email) {
        return setErr("Fill all the fields");
      }
      const body = { email, password };
      setSubmit(true);
      const response = await loginAdmin(body);
      setSubmit(false);
      if (response.status !== 200) {
        return setErr(response.data.message);
      }
      if (!response.data.is_active) {
        return setErr(
          "You are not allowed to use the system at the moment, Please contact the Administration for account updates"
        );
      }
      Cookies.set("auth", JSON.stringify(response.data), { expires: 0.5 });
      return navigate("/");
    } catch (error) {
      setSubmit(false);
      console.log({ error });
    }
  };
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        {err ? (
          <p
            style={{
              textAlign: "center",
              color: "red",

              fontSize: 16,
            }}
          >
            {err}
          </p>
        ) : null}
        <Header
          color="teal"
          textAlign="center"
          style={{ height: 100, maxWidth: 400 }}
        >
          <Image
            src={logo}
            style={{ height: "100%", width: "65%" }}
            resizeMode="contain"
          />
        </Header>

        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              onChange={(e) => {
                setErr(null);
                setEmail(e.target.value);
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e) => {
                setErr(null);
                setpassword(e.target.value);
              }}
            />

            <Button
              loading={isSubmit}
              color="teal"
              fluid
              size="large"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </Segment>
        </Form>
        <Message>Need help? hello@msosidrop.co.tz</Message>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
