import axios from "axios";
const server_url = "https://server.msosidrop.co.tz";

export const product_api = axios.create({
  baseURL: `${server_url}:5000/api`,
  timeout: 10000,
});

export const shop_api = axios.create({
  baseURL: `${server_url}:5100/api`,
  timeout: 10000,
});

export const buyer_api = axios.create({
  baseURL: `${server_url}:5200/api`,
  timeout: 10000,
});

export const order_api = axios.create({
  baseURL: `${server_url}:5300/api`,
  timeout: 10000,
});

export const wallet_api = axios.create({
  baseURL: `${server_url}:5400/api`,
  timeout: 10000,
});

export const dropper_api = axios.create({
  baseURL: `${server_url}:5700/api`,
  timeout: 10000,
});

export const admin_api = axios.create({
  baseURL: `${server_url}:8500/api`,
  timeout: 10000,
});
