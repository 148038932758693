import React, { useState } from "react";
import { Form, Message, Image, Loader } from "semantic-ui-react";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { rightStyles } from "../../constants";
import { signUpInfluencer } from "../../controllers";
import "../../constants/style.css";

const AddInfluencer = () => {
  const [isRegistered, setRegistered] = useState(false);
  const [name, setName] = useState(null);
  const [phone, setphone] = useState(null);
  const [email, setemail] = useState(null);
  const [level, setLevel] = useState("low");
  const [err, setErr] = useState(null);
  const [isMobile, setMobile] = useState(false);
  const [accName, setAccName] = useState(null);
  const [accNumber, setAccNumber] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!name || !email || !accName || !accNumber || !provider) {
        return setErr("Fill all basic fields");
      }
      setLoading(true);
      setRegistered(false);
      setErr(null);
      const body = {
        name,
        phone,
        email,
        level,
        bank_account: {
          account_name: accName,
          account_number: accNumber,
          isMobile: isMobile,
          provider,
        },
      };
      const response = await signUpInfluencer(body);
      console.log({ response });
      setLoading(false);
      if (response.status === 201) {
        setRegistered(true);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <SideNav num={7} />
      <div className="container">
        <HeaderComp />

        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <h2 style={{ marginBottom: 20 }}>Register a new Influencer</h2>

          <Form success={isRegistered}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Name"
                placeholder="Slobo John"
                onChange={(e) => {
                  setErr(null);
                  setName(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Email"
                placeholder="abcd@bda.com"
                onChange={(e) => {
                  setErr(null);
                  setemail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Mobile phone"
                placeholder="25570100100"
                onChange={(e) => {
                  setErr(null);
                  setphone(e.target.value);
                }}
              />
              <Form.Select
                fluid
                label="Level"
                options={[
                  { key: "1", text: "Low", value: "low" },
                  { key: "2", text: "Medium", value: "medium" },
                  { key: "3", text: "Hign", value: "high" },
                ]}
                placeholder="Level"
                onChange={(e, i) => {
                  setErr(null);
                  setLevel(i.value);
                }}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Select
                fluid
                label="Kind"
                options={[
                  { key: "1", text: "Bank", value: false },
                  { key: "2", text: "Mobile", value: true },
                ]}
                placeholder="Kind"
                onChange={(e, i) => {
                  setErr(null);
                  setMobile(i.value);
                }}
              />
              <Form.Input
                fluid
                label="Account No"
                placeholder="25570100100"
                onChange={(e) => {
                  setErr(null);
                  setAccNumber(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Account Name"
                placeholder="John Snow"
                onChange={(e) => {
                  setErr(null);
                  setAccName(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Bank / Network"
                placeholder="CRDB"
                onChange={(e) => {
                  setErr(null);
                  setProvider(e.target.value);
                }}
              />
            </Form.Group>

            <Message
              success
              header="Account Registered"
              content="Email has been sent with login information"
            />
            {err ? (
              <p style={{ color: "red", textAlign: "center" }}>{err}</p>
            ) : null}

            <Form.Button
              loading={isLoading}
              onClick={handleSubmit}
              style={{ marginBottom: 50 }}
            >
              Submit
            </Form.Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddInfluencer;
