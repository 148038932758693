import React, { useState } from "react";
import { shop_api } from "../../controllers/apis";
import { Form, Message, Image, Loader } from "semantic-ui-react";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { rightStyles } from "../../constants";
import { useGeolocated } from "react-geolocated";
import imTe from "../../assets/imgs/image-text.png";
import { signUpStore } from "../../controllers";
import "../../constants/style.css";

const options = [
  { key: "1", text: "Have self delivery", value: true },
  { key: "2", text: "Dropper delivery", value: false },
];

const pickUpOptions = [
  { key: "1", text: "Self pickup", value: true },
  { key: "2", text: "Non self pickup", value: false },
];

const smartPhoneOptions = [
  { key: "1", text: "Yes", value: true },
  { key: "2", text: "No", value: false },
];

const cityOptions = [
  { key: "1", text: "Dar es salaam", value: "Dar es salaam" },
];

const districtOptions = [
  { key: "1", text: "Ubungo", value: "Ubungo" },
  { key: "2", text: "Kinondoni", value: "Kinondoni" },
  { key: "3", text: "Ilala", value: "Ilala" },
  { key: "4", text: "Kigamboni", value: "Kigamboni" },
  { key: "5", text: "Temeke", value: "Temeke" },
];

const AddStore = () => {
  const [value, setValue] = useState("instantly");
  const [isRegistered, setRegistered] = useState(false);
  const [isLogoUpload, setisLogoUpload] = useState(false);
  const [zones, setZones] = useState([]);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [name, setname] = useState(null);
  const [logo, setlogo] = useState(null);
  const [cover, setcover] = useState(null);
  const [phone, setphone] = useState(null);
  const [cphone, setCphone] = useState(null);
  const [isDeliver, setDelivery] = useState(false);
  const [selfPickup, setSelfPickup] = useState(true);
  const [email, setemail] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [percent, setpercent] = useState(null);
  const [city, setcity] = useState(null);
  const [district, setdistrict] = useState(null);
  const [ward, setward] = useState(null);
  const [street, setstreet] = useState(null);
  const [zone, setZone] = useState(null);
  const [hasSmart, setHasSmart] = useState(true);
  const [comment, setcomment] = useState(null);
  const [err, setErr] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const useCurentLoc = (e, d) => {
    if (d.checked) {
      setLat(coords?.latitude);
      setLon(coords?.longitude);
    } else {
      setLat("");
      setLon("");
    }
  };

  React.useEffect(() => {
    shop_api
      .get("/zone")
      .then((res) => {
        if (res.status === 200) {
          let z = [];
          res.data.forEach((e) => {
            z.push({ key: e._id, text: e.name, value: e._id });
          });
          setZones(z);
        }
      })
      .catch((err) => console.log({ err }));
  }, []);

  const handleChange = (i, { value }) => setValue(value);

  const uploadLogo = async (e, ty) => {
    try {
      setisLogoUpload(true);
      var formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      formdata.append("upload_preset", "from_shop");

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://api.cloudinary.com/v1_1/dukaapp/image/upload",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setisLogoUpload(false);
          if (ty === "l" && result.secure_url) {
            setlogo(result.secure_url);
          } else if (ty === "c" && result.secure_url) {
            setcover(result.secure_url);
          } else {
            setErr("Failed to upload image, try again");
          }
        })
        .catch((error) => {
          setisLogoUpload(false);
          console.log("error", error);
        });
    } catch (error) {
      setisLogoUpload(false);
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!name || !logo || !cover || !phone || !lat || !lon) {
        return setErr("Fill all basic fields");
      }
      setLoading(true);
      setRegistered(false);
      setErr(null);
      const body = {
        name: name,
        logo: logo,
        cover: cover,
        email: email,
        phone: phone,
        self_pickup: selfPickup,
        self_delivery: isDeliver,
        customerService: cphone,
        zone: zone,
        has_smart_phone: hasSmart,
        percent: Number(percent),
        whatsapp: whatsapp,
        comment: comment,
        kind: "food",
        pay_structure: value,
        address: { city, district, ward, street },
        loc: { type: "Point", coordinates: [lat, lon] },
      };

      const response = await signUpStore(body);
      console.log({ response });
      setLoading(false);
      if (response.status === 201) {
        setRegistered(true);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <SideNav num={4} />
      <div className="container">
        <HeaderComp />

        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <h2 style={{ marginBottom: 50 }}>Register a new Restaurant</h2>

          <Form success={isRegistered}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Store name"
                placeholder="Store name"
                onChange={(e) => {
                  setErr(null);
                  setname(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Store phone"
                placeholder="25570100100"
                onChange={(e) => {
                  setErr(null);
                  setphone(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Customer Service phone"
                placeholder="25570100100"
                onChange={(e) => {
                  setErr(null);
                  setCphone(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Store email"
                placeholder="Store email"
                onChange={(e) => {
                  setErr(null);
                  setemail(e.target.value);
                }}
              />
              <Form.Select
                fluid
                label="Delivery mode"
                options={options}
                placeholder="Delivery"
                onChange={(e, i) => {
                  setErr(null);

                  setDelivery(i.value);
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Whatsapp number"
                placeholder="25570100100"
                onChange={(e) => {
                  setErr(null);
                  setwhatsapp(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Percent chargeable"
                placeholder="13"
                onChange={(e) => {
                  setErr(null);
                  setpercent(e.target.value);
                }}
              />
              <Form.Select
                fluid
                label="Pick-up mode"
                options={pickUpOptions}
                placeholder="Self pickup"
                onChange={(e, i) => {
                  setErr(null);
                  setSelfPickup(i.value);
                }}
              />
              <Form.Select
                fluid
                label="Has smart phone?"
                options={smartPhoneOptions}
                placeholder="Yes"
                onChange={(e, i) => {
                  setErr(null);
                  setHasSmart(i.value);
                }}
              />
              {zones.length > 0 ? (
                <Form.Select
                  fluid
                  label="Zone"
                  options={zones}
                  placeholder="Sinza"
                  onChange={(e, i) => {
                    setErr(null);
                    setZone(i.value);
                  }}
                />
              ) : null}
            </Form.Group>
            <Form.Group inline>
              <label>Payment Structure</label>
              <Form.Radio
                label="Instantly"
                value="instantly"
                checked={value === "instantly"}
                onChange={handleChange}
              />
              <Form.Radio
                label="Daily"
                value="daily"
                checked={value === "daily"}
                onChange={handleChange}
              />
              <Form.Radio
                label="Weekly"
                value="weekly"
                checked={value === "weekly"}
                onChange={handleChange}
              />
            </Form.Group>
            <h3>Address</h3>
            <Form.Group widths="equal">
              <Form.Select
                fluid
                label="City"
                options={cityOptions}
                placeholder="city"
                onChange={(e, i) => {
                  setErr(null);

                  setcity(i.value);
                }}
              />
              <Form.Select
                fluid
                label="District"
                options={districtOptions}
                placeholder="District"
                onChange={(e, i) => {
                  setErr(null);

                  setdistrict(i.value);
                }}
              />
              <Form.Input
                fluid
                label="Ward"
                placeholder="Kijitonyama"
                onChange={(e) => {
                  setErr(null);
                  setward(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Street"
                placeholder="Mwenge sokoni"
                onChange={(e) => {
                  setErr(null);
                  setstreet(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Checkbox
                style={{ marginTop: 30 }}
                label="Use current location"
                toggle
                onChange={useCurentLoc}
              />
              <Form.Input
                label="Latitude"
                placeholder="6.2234132"
                value={lat}
                onChange={(e) => {
                  setErr(null);
                  setLat(e.target.value);
                }}
              />
              <Form.Input
                label="Longitude"
                placeholder="-39.341341"
                value={lon}
                onChange={(e) => {
                  setErr(null);
                  setLon(e.target.value);
                }}
              />
            </Form.Group>

            <Form.TextArea
              label="Comment"
              placeholder="Write your comment about this restaurant..."
              onChange={(e) => {
                setErr(null);
                setcomment(e.target.value);
              }}
            />

            <Form.Group widths="equal">
              <div style={{ marginRight: 20 }}>
                <span>Logo</span>
                <input type="file" onChange={(e) => uploadLogo(e, "l")} />
              </div>

              <div style={{ marginLeft: 20 }}>
                <span>Cover</span>
                <input type="file" onChange={(e) => uploadLogo(e, "c")} />
              </div>
            </Form.Group>
            <Loader active={isLogoUpload} inline />
            {!isLogoUpload ? (
              <Form.Group widths="equal">
                <Image src={logo ? logo : imTe} as="a" size="small" />

                <Image
                  src={cover ? cover : imTe}
                  as="a"
                  size="medium"
                  style={{ marginLeft: 200 }}
                />
              </Form.Group>
            ) : null}

            <Form.Checkbox label="Agreed to the Terms and Conditions" />
            <Message
              success
              header="Account Registered"
              content="Go back to verify the restaurant"
            />
            {err ? (
              <p style={{ color: "red", textAlign: "center" }}>{err}</p>
            ) : null}

            <Form.Button
              loading={isLoading}
              onClick={handleSubmit}
              style={{ marginBottom: 50 }}
            >
              Submit
            </Form.Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddStore;
