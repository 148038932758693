import React, { useState, useEffect } from "react";
import SideNav from "../../components/sideNav";
import {
  Icon,
  Menu,
  Table,
  Loader,
  Input,
  Label,
  Button,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import HeaderComp from "../../components/header";
import { getInfluencers } from "../../controllers";
import { colors } from "../../constants";
import "../../constants/style.css";

function Influencers() {
  const navigate = useNavigate();
  const [influencers, setinfluencer] = useState([]);
  const [ogInfluencers, setOgInfluencer] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [num, setNum] = useState(null);
  const [isSearch, setSearching] = useState(false);
  const [page, setpage] = useState(0);

  useEffect(() => {
    setLoading(true);
    getInfluencers(50, page)
      .then((res) => {
        setLoading(false);
        setinfluencer(res.data);
        setOgInfluencer(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [page]);

  console.log({ influencers });

  const heading = [
    "sn",
    "name",
    "phone",
    "status",
    "balance",
    "email",
    "level",
  ];

  const active = {
    fontWeight: "bold",
    color: colors.primary,
  };

  return (
    <div>
      <SideNav num={7} />
      <div className="container">
        <HeaderComp />
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 36,
                  marginRight: 20,
                  marginBottom: 0,
                }}
              >
                Influencers
              </p>
              <Button
                icon
                labelPosition="right"
                color="green"
                onClick={() => navigate("/add-influencer")}
              >
                Add
                <Icon name="plus" />
              </Button>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  {heading.map((one) => (
                    <Table.HeaderCell key={one}>{one}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {influencers.map((shop, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{shop.name}</Table.Cell>
                    <Table.Cell>{shop.phone}</Table.Cell>

                    <Table.Cell>
                      <Label
                        style={{
                          backgroundColor: shop.is_active
                            ? colors.green
                            : "red",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {shop.is_active ? "active" : "inactive"}
                      </Label>
                    </Table.Cell>
                    <Table.Cell>TZS {shop.balance.toLocaleString()}</Table.Cell>
                    <Table.Cell>{shop.email}</Table.Cell>
                    <Table.Cell>
                      <Label
                        style={{
                          backgroundColor:
                            shop.level === "high" ? colors.green : "orange",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {shop.level}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="9">
                    <Menu floated="right" pagination>
                      <Menu.Item
                        as="a"
                        icon
                        onClick={() =>
                          page > 0
                            ? setpage(page - 1)
                            : console.log("First page")
                        }
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(0)}
                        style={page === 0 ? active : null}
                      >
                        1
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(1)}
                        style={page === 1 ? active : null}
                      >
                        2
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(2)}
                        style={page === 2 ? active : null}
                      >
                        3
                      </Menu.Item>
                      <Menu.Item
                        as="a"
                        onClick={() => setpage(3)}
                        style={page === 3 ? active : null}
                      >
                        4
                      </Menu.Item>
                      <Menu.Item as="a" icon onClick={() => setpage(page + 1)}>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default Influencers;
