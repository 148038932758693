import React, { useEffect, useState } from "react";
import SideNav from "../../components/sideNav";
import { Button, Loader, Icon, Table, Label } from "semantic-ui-react";
import { colors, rightStyles } from "../../constants";
import HeaderComp from "../../components/header";
import { getAllAdmins } from "../../controllers";
import "../../constants/style.css";

function Admins() {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getAllAdmins()
      .then((res) => {
        setLoading(false);
        setAdmins(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, []);

  console.log({ admins });

  const head = [
    "sn",
    "name",
    "e-mail",
    "access control",
    "office",
    "registration date",
    "is active",
    "more",
  ];

  return (
    <div>
      <SideNav num={6} />
      <div className="container">
        <HeaderComp />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: 36,
              marginRight: 20,
              marginBottom: 0,
            }}
          >
            Admins
          </p>
        </div>
        {isLoading ? (
          <div style={{ margin: 30 }}>
            <Loader active inline="centered" />
          </div>
        ) : (
          <Table celled compact definition>
            <Table.Header fullWidth>
              <Table.Row>
                {head.map((one) => (
                  <Table.HeaderCell>{one}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {admins.map((admin, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{admin.name}</Table.Cell>
                  <Table.Cell>{admin.email}</Table.Cell>
                  <Table.Cell>
                    {admin.access?.name}{" "}
                    <Label>Level: {admin.access?.level}</Label>{" "}
                  </Table.Cell>
                  <Table.Cell>{admin.office}</Table.Cell>
                  <Table.Cell>
                    {new Date(admin.createdAt).toDateString()}
                  </Table.Cell>
                  <Table.Cell>
                    <Label
                      style={{
                        color: "white",
                        backgroundColor: admin.is_active
                          ? colors.green
                          : colors.primary,
                      }}
                    >
                      {admin.is_active ? "True" : "False"}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="ellipsis vertical" link />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan="7">
                  <Button
                    floated="right"
                    icon
                    labelPosition="left"
                    primary
                    size="small"
                    style={{ backgroundColor: colors.primary }}
                  >
                    <Icon name="user" /> Add Admin
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </div>
    </div>
  );
}

export default Admins;
