import React, { useEffect, useState } from "react";
import SideNav from "../../components/sideNav";
import {
  Dropdown,
  Icon,
  Card,
  Divider,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import _ from "lodash";
import { colors, rightStyles } from "../../constants";
import HeaderComp from "../../components/header";
import { useNavigate } from "react-router-dom";
import { MyCard } from "../../components/more";
import {
  getAllBuyersByTime,
  getAllDroppersByTime,
  getAllOrdersByTime,
  getAllStoresByTime,
} from "../../controllers";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import "../../constants/style.css";

function Dashboard() {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [muda, setMuda] = useState("today");
  const [orders, setOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [stores, setStores] = useState([]);
  const [storeCount, setStoreCount] = useState(0);
  const [drivers, setDrivers] = useState([]);
  const [driverCount, setDriverCount] = useState(0);
  const [buyers, setBuyers] = useState([]);
  const [buyerCount, setBuyerCount] = useState(0);
  const [days, setDays] = useState([]);

  const dates = [...Array(7)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - i);
    return d;
  });
  const weeks = [
    "Week 7",
    "Week 6",
    "Week 5",
    "Week 4",
    "Week 3",
    "Week 2",
    "This Week",
  ];
  const months = [...Array(7)].map((_, i) => {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    return d;
  });

  const getDays = (pasts, muda) => {
    const orderedByDates = _.groupBy(pasts, (element) =>
      new Date(element.createdAt).toLocaleDateString()
    );
    let orderedByDateArray = _.values(orderedByDates);

    let dateArr = dates.map((date) => {
      let one = {
        date: date,
        paid: 0,
        unpaid: 0,
        day: `${date.toString().split(" ")[0]}, ${
          date.toString().split(" ")[1]
        } ${date.toString().split(" ")[2]}`,
      };
      orderedByDateArray.map((obj) => {
        let paid = 0;
        let unpaid = 0;

        if (
          new Date(date).toLocaleDateString() ===
          new Date(obj[0].createdAt).toLocaleDateString()
        ) {
          obj.forEach((o) => {
            if (o.isPaid) {
              paid = 1 + paid;
            } else {
              unpaid = 1 + unpaid;
            }
          });
          one = { ...one, paid, unpaid };
        }
      });
      return one;
    });

    return setDays(dateArr.reverse());
  };

  useEffect(() => {
    setLoading(true);
    getAllOrdersByTime(muda)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setOrders(res.data.orders);
          setOrderCount(res.data.count);
          setPastOrders(res.data.ordersPast);

          getDays(res.data.ordersPast, muda);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });

    getAllStoresByTime(muda)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setStores(res.data.shops);
          setStoreCount(res.data.count);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });

    getAllBuyersByTime(muda)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setBuyers(res.data.buyers);
          setBuyerCount(res.data.count);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });

    getAllDroppersByTime(muda)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setDrivers(res.data.drivers);
          setDriverCount(res.data.count);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, [muda]);

  const options = [
    { key: 1, text: "Today", value: "today" },
    { key: 2, text: "Weekly", value: "week" },
    { key: 3, text: "Monthly", value: "month" },
    { key: 4, text: "All Time", value: "all" },
  ];
  return (
    <div>
      <SideNav num={1} />
      <div className="container">
        <HeaderComp />
        <div>
          <Dropdown
            options={options}
            selection
            value={muda}
            fluid
            placeholder="Select Time"
            onChange={(e, d) => setMuda(d.value)}
          />
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
        </div>

        <div style={{ paddingTop: 20 }}>
          <Card.Group centered>
            <MyCard
              rangi={colors.secondary}
              title="Total Orders"
              center={`${orderCount}`}
              down="+0%"
            />
            <MyCard
              rangi={colors.primary}
              title="Total Customers"
              center={`${buyerCount}`}
              down="+0%"
            />
            <MyCard
              rangi={colors.green}
              title="Total Restaurants"
              center={`${storeCount}`}
              down="0%"
            />
            <MyCard
              rangi={colors.fourth}
              title="Total Droppers"
              center={`${driverCount}`}
              down="0%"
            />
          </Card.Group>
          <div style={{ marginTop: 50, marginBottom: 50 }}>
            <Divider horizontal>
              <Header as="h5">
                <p>Orders</p>
              </Header>
            </Divider>
          </div>
        </div>
        {days.length > 0 ? (
          <div className="lineChart">
            <LineChart
              width={window.innerWidth - 250}
              height={300}
              data={days}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="paid"
                stroke="#16B102"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="unpaid"
                stroke="#DA2906"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Dashboard;
