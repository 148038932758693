import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//pages
import Dashboard from "./pages/dashbord";
import Orders from "./pages/orders";
import Customers from "./pages/customers";
import Stores from "./pages/stores";
import Droppers from "./pages/droppers";
import Admins from "./pages/admins";
import LoginForm from "./pages/auth/Login";
import Profile from "./pages/admins/Profile";
import AddStore from "./pages/stores/AddStore";
import StoreDetails from "./pages/stores/StoreDetails";
import AddDropper from "./pages/droppers/AddDropper";
import DropperDetails from "./pages/droppers/DropperDetails";
import BuyerDetails from "./pages/customers/BuyerDetails";
import OrderDetails from "./pages/orders/OderDetails";
import Influencers from "./pages/influencers";
import AddInfluencer from "./pages/influencers/AddInfluencer";
import ProductUpdate from "./pages/stores/UpdateProduct";
import Revenues from "./pages/Revenues";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/orders" element={<Orders />} />
        <Route exact path="/customers" element={<Customers />} />
        <Route exact path="/stores" element={<Stores />} />
        <Route exact path="/droppers" element={<Droppers />} />
        <Route exact path="/admins" element={<Admins />} />
        <Route exact path="/login" element={<LoginForm />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/add-store" element={<AddStore />} />
        <Route exact path="/order-details" element={<OrderDetails />} />
        <Route exact path="/buyer-details" element={<BuyerDetails />} />
        <Route exact path="/store-details" element={<StoreDetails />} />
        <Route exact path="/add-dropper" element={<AddDropper />} />
        <Route exact path="/dropper-details" element={<DropperDetails />} />
        <Route exact path="/influencers" element={<Influencers />} />
        <Route exact path="/add-influencer" element={<AddInfluencer />} />
        <Route exact path="/update-product" element={<ProductUpdate />} />
        <Route exact path="/revenues" element={<Revenues />} />
      </Routes>
    </Router>
  );
}

export default App;
