import React, { useState } from "react";
import { Form, Message, Image, Loader } from "semantic-ui-react";
import HeaderComp from "../../components/header";
import SideNav from "../../components/sideNav";
import { rightStyles } from "../../constants";
import imTe from "../../assets/imgs/image-text.png";
import { signUpDropper } from "../../controllers";
import "../../constants/style.css";

const AddDropper = () => {
  const [isRegistered, setRegistered] = useState(false);
  const [isLogoUpload, setisLogoUpload] = useState(false);
  const [last_name, setLastName] = useState(null);
  const [first_name, setfirstName] = useState(null);
  const [logo, setlogo] = useState(null);
  const [cover, setcover] = useState(null);
  const [phone, setphone] = useState(null);
  const [email, setemail] = useState(null);
  const [nida, setNida] = useState(null);
  const [licenceNo, setLicenceNo] = useState(null);
  const [city, setCity] = useState(null);
  const [comment, setcomment] = useState(null);
  const [err, setErr] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const uploadLogo = async (e, ty) => {
    try {
      setisLogoUpload(true);
      var formdata = new FormData();
      formdata.append("file", e.target.files[0]);
      formdata.append("upload_preset", "from_shop");

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://api.cloudinary.com/v1_1/dukaapp/image/upload",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setisLogoUpload(false);
          if (ty === "p" && result.secure_url) {
            setlogo(result.secure_url);
          } else if (ty === "l" && result.secure_url) {
            setcover(result.secure_url);
          } else {
            setErr("Failed to upload image, try again");
          }
        })
        .catch((error) => {
          setisLogoUpload(false);
          console.log("error", error);
        });
    } catch (error) {
      setisLogoUpload(false);
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    try {
      if (!first_name || !logo || !cover || !phone || !last_name || !city) {
        return setErr("Fill all basic fields");
      }
      setLoading(true);
      setRegistered(false);
      setErr(null);
      const body = {
        first_name,
        last_name,
        phone,
        email,
        nida,
        license_no: licenceNo,
        profile_img: logo,
        license_url: cover,
        registered_city: city,
        comment,
      };
      const response = await signUpDropper(body);
      console.log({ response });
      setLoading(false);
      if (response.status === 201) {
        setRegistered(true);
      } else {
        setErr(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <SideNav num={5} />
      <div className="container">
        <HeaderComp />

        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <h2 style={{ marginBottom: 20 }}>Register a new Dropper</h2>

          <Form success={isRegistered}>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="First name"
                placeholder="John"
                onChange={(e) => {
                  setErr(null);
                  setfirstName(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Last name"
                placeholder="Slobo"
                onChange={(e) => {
                  setErr(null);
                  setLastName(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Mobile phone"
                placeholder="25570100100"
                onChange={(e) => {
                  setErr(null);
                  setphone(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Email"
                placeholder="abcd@bda.com"
                onChange={(e) => {
                  setErr(null);
                  setemail(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="NIDA"
                placeholder="191008105910000245"
                onChange={(e) => {
                  setErr(null);
                  setNida(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="Licence number"
                placeholder="4000381390"
                onChange={(e) => {
                  setErr(null);
                  setLicenceNo(e.target.value);
                }}
              />
              <Form.Input
                fluid
                label="City"
                placeholder="Dar es salaam"
                onChange={(e) => {
                  setErr(null);
                  setCity(e.target.value);
                }}
              />
            </Form.Group>

            <Form.TextArea
              label="Comment"
              placeholder="Write your comment about this dropper..."
              onChange={(e) => {
                setErr(null);
                setcomment(e.target.value);
              }}
            />

            <Form.Group widths="equal">
              <div style={{ marginRight: 20 }}>
                <span>Profile</span>
                <input type="file" onChange={(e) => uploadLogo(e, "p")} />
              </div>

              <div style={{ marginLeft: 20 }}>
                <span>Licence</span>
                <input type="file" onChange={(e) => uploadLogo(e, "l")} />
              </div>
            </Form.Group>
            <Loader active={isLogoUpload} inline />
            {!isLogoUpload ? (
              <Form.Group widths="equal">
                <Image src={logo ? logo : imTe} as="a" size="small" />
                <Image
                  src={cover ? cover : imTe}
                  as="a"
                  size="small"
                  style={{ marginLeft: 200 }}
                />
              </Form.Group>
            ) : null}

            <Form.Checkbox label="Agreed to the Terms and Conditions" />
            <Message
              success
              header="Account Registered"
              content="Go back to add vehicle to this dropper"
            />
            {err ? (
              <p style={{ color: "red", textAlign: "center" }}>{err}</p>
            ) : null}

            <Form.Button
              loading={isLoading}
              onClick={handleSubmit}
              style={{ marginBottom: 50 }}
            >
              Submit
            </Form.Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddDropper;
